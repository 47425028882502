<script>
export default {
    name: "list-free",

    emits: "update:modelValue",

    props: {
        modelValue: {
            type: Array,
            default: () => { return []; }
        }
    },

    data: function () {
        return {
            model: [],
            search: ''
        };
    },

    methods: {
        initModel: function (model) {
            this.model = JSON.parse(JSON.stringify(model));
        },

        add: function () {
            if (! this.search) {
                return;
            }

            this.model.push(this.search);
            this.search = '';

            this.update();
        },

        remove: function (index) {
            this.model.splice(index, 1);
            this.update();
        },

        update: function () {
            this.$emit('update:modelValue', this.model);
        },

        focus: function () {
            this.$refs.search.click();
            this.$refs.search.focus();
        },
    },

    watch: {
        modelValue: function (value) {
            if (! this.model?.length && value?.length) {
                this.initModel(value);
            }
        },
    },

    created() {
        this.initModel(this.modelValue);
    }
}
</script>

<template>
    <div class="form-control" @click="focus">
        <div class="btn btn-primary me-3" v-for="(text, index) in model" :key="text">
            <span>{{ text }}</span>
            <i class="ms-2 fas fa-trash-alt text-danger" @click.prevent.capture="remove(index)"></i>
        </div>
        <input ref="search" class="hidden-input" type="search" v-model="search" @keydown.enter.prevent.capture="add">
    </div>
</template>

<style scoped>
.fit-content {

}
.hidden-input {
    border: 0;
    outline: 0;
    width: auto;
    min-width: 250px;
}
</style>