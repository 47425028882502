<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "file-private-viewer",

    props: {
        model: {
            type: String,
            required: true
        },

        id: {
            type: String,
            required: true
        },

        fileId: {
            type: String,
            required: true
        },

        download: {
            type: Boolean,
            default: false
        },

        clickEvent: {}
    },

    methods: {
        fetch: function () {
            this.axios.get(`files/private/${this.model}/${this.id}/${this.fileId}`, {responseType: 'blob'}).then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                if (this.download) {
                    const a = document.createElement('a')
                    a.href = URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}))
                    a.download = this.path.substring(this.path.lastIndexOf('/'))
                    a.click()
                } else {
                    window.open(url, '_blank');
                }
                return response.status;
            })
        },
    },

    watch: {
        clickEvent: function () {
            this.fetch()
        },
    },
})
</script>

<template>
    <span class="cursor-pointer">
        <slot :click="fetch">

        </slot>
    </span>
</template>

<style scoped>

</style>
