<script>
import {mapGetters} from "vuex";

export default {
    name: "project-step-deadline-card",

    props: {
        projectStepId: {
            type: [Number, String],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            deadlines: 'projectStep/deadlines',
            loading: 'projectStep/deadlinesLoading',
        })
    },

    watch: {
        projectStepId: {
            immediate: true,
            deep: true,
            handler: function (projectStepId) {
                if (projectStepId) {
                    this.$store.dispatch('projectStep/deadlines', projectStepId);
                }
            },
        }
    },

    unmounted() {
        this.$store.dispatch('projectStep/clearDeadlines');
    }
}
</script>

<template>
    <div class="card" v-if="!loading && deadlines?.length">
        <div class="card-header bg-danger">
            <h4 class="card-title d-flex align-items-center justify-content-center">
                <i class="mdi mdi-calendar-alert me-2 font-size-18 text-white"></i>
                <span class="text-uppercase">{{ $t('base.deadline') }}</span>
            </h4>
        </div>
        <div class="card-body bg-soft-danger rounded-bottom">
            <b-overlay :show="loading"></b-overlay>
            <p class="font-weight-bold mb-0" v-for="(deadline, index) in deadlines" :key="index">
                <span class="bg-gradient bg-danger p-2 rounded text-white me-2">
                    <i v-if="deadline.interaction?.icon" :class="deadline.interaction.icon" :style="{color: deadline.interaction?.color}"></i>
                </span>
                <span>
                    {{ $t(deadline.translation_key, {task: deadline.task.ucFirst(), deadline: deadline.deadline?.printDate()}) }}
                </span>
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>