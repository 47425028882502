<script>
import {mapGetters} from "vuex";
import Avatar from "./user/avatar.vue";

export default {
    name: "project-history",
    components: {Avatar},

    props: {
        projectId: {
            type: [String, Number],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            loading: 'project/loading',
            history: 'project/history',
            locale: 'trans/locale',
        })
    },

    methods: {
        fetchHistory(id) {
            this.$store.dispatch('project/history', id);
        },
    },

    /*watch: {
        projectId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetchHistory(id);
                }
            },
        }
    }*/
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-body pe-0">
                <div class="mh-75vh overflow-y-scroll solid-scroller">
                    <ul class="verti-timeline list-unstyled">
                        <li v-for="(item, index) in history" :key="index" class="event-list">
                            <div class="event-timeline-dot">
                                <i class="bx bx-right-arrow-circle"></i>
                            </div>
                            <div class="d-flex">
                                <div class="flex-shrink-0 text-center me-3">
                                    <i class="h2 text-primary" :class="item.icon"></i>
                                    <br>
                                    <avatar class="opacity-50 opacity-hover-100" :user="item.user" size="sm"></avatar>
                                </div>
                                <div class="flex-grow-1">
                                    <div>
                                        <h5>{{ item.title }}</h5>
                                        <p v-if="item.description" class="text-muted mb-0">{{ item.description }}</p>
                                        <p class="d-flex align-items-center mt-2 font-size-14">
                                            <span class="me-2 opacity-50">
                                                <i class="fas fa-calendar-check"></i>
                                            </span>
                                            <span>{{ item.closed_at.printDate(locale) }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>
.mh-75vh {
    max-height: 75vh;
}
</style>