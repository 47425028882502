<script>
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import SimpleTable from "./simple-table.vue";
import StatusBadge from "./status-badge.vue";
import FilePrivateViewer from "./file -private-viewer.vue";
import Swal from "sweetalert2";
import ProjectStepInteractionForm from "../forms/project-step-interaction-form.vue";

export default defineComponent({
    name: "project-step-interaction-list",

    components: {ProjectStepInteractionForm, FilePrivateViewer, StatusBadge, SimpleTable},

    emits: ['close', 'refresh'],

    props: {
        projectStep: {
            type: Object,
            required: true
        },

        project: {
            type: Object,
            required: true
        },

        interaction: {
            type: Object
        }
    },

    data: function () {
        return {
            selected: null,
            close: null,
            updated: 1
        }
    },

    computed: {
        ...mapGetters({
            loading: 'projectStepInteraction/loading',
            projectStepInteractions: 'projectStepInteraction/projectList',
            locale: 'trans/locale',
        }),

        link: function () {
            switch (this.interaction?.translation_key) {
                case 'interactions::interactions.interactions.reminder':
                    return 'routes./reminders/my-reminders/:id(\\d+)'
                case 'interactions::interactions.interactions.meeting':
                    return 'routes./interactions/my-meetings/:id(\\d+)'
                case 'interactions::interactions.interactions.implement':
                    return 'routes./interactions/my-implements/:id(\\d+)'
                case 'interactions::interactions.interactions.contract':
                    return 'routes./interactions/my-contracts/:id(\\d+)'
                case 'interactions::interactions.interactions.offer':
                    return 'routes./interactions/my-offers/:id(\\d+)'
                case 'interactions::interactions.interactions.task':
                    return 'routes./tasks/accessible-tasks/:id(\\d+)'
                case 'interactions::interactions.interactions.invoicing':
                    return 'routes./invoices/accessible-invoices/:id(\\d+)'
                default:
                    return null
            }
        },

        fields: function () {
            switch (this.interaction?.translation_key) {
                case 'interactions::interactions.interactions.reminder':
                    return [
                        {
                            key: "event",
                            label: this.$t('projects::project_step_interactions.relationships.event').ucFirst(),
                            formatter: (value) => value ? (value?.translation_key ? this.$t(value.translation_key) : value?.title) : value
                        },
                        {
                            key: "date",
                            label: this.$t('reminders::reminders.columns.due_next').ucFirst(),
                            formatter: (value, index, item) => item.event?.due_next ? (item.event?.due_next.printDate('hu-HU', {dateStyle: 'short', timeStyle: 'short'})) : ( item.event?.date ? item.event?.date?.printDate('hu-HU', {dateStyle: 'short', timeStyle: 'short'}) : item.event?.date),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "message",
                            label: this.$t('reminders::reminders.columns.message').ucFirst(),
                            formatter: (value, key, item) => item.event ? (item.event.message_translation_key ? this.$t(item.event.message_translation_key)?.truncate(50) : item.event.message?.truncate(50)) : null
                        },
                        {
                            key: "notification",
                            label: this.$t('projects::project_step_interactions.columns.notification').ucFirst(),
                        },
                        {
                            key: "is_active",
                            label: this.$t('reminders::reminders.columns.is_active').ucFirst(),
                            formatter: (value, index, item) => item?.event?.is_active,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        },
                    ];
                case 'interactions::interactions.interactions.meeting':
                    return [
                        {
                            key: 'status',
                            label: '',
                        },
                        {
                            key: "event",
                            label: this.$t('projects::project_step_interactions.relationships.event').ucFirst(),
                            formatter: (value) => value ? value.name : value
                        },
                        {
                            key: "user",
                            label: this.$t('interactions::meetings.relationships.user').ucFirst(),
                            formatter: (value, index, item) => item.event?.user
                        },
                        {
                            key: "users",
                            label: this.$t('interactions::meetings.relationships.users').ucFirst(),
                            formatter: (value, index, item) => item.event?.users
                        },
                        {
                            key: "notification",
                            label: this.$t('projects::project_step_interactions.columns.notification').ucFirst(),
                            formatter: (value, index, item) => item.event?.notification?.truncate(50)
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        },
                    ];
                case 'interactions::interactions.interactions.implement':
                    return [
                        {
                            key: "name",
                            label: this.$tc('projects::implements.implement', 1).ucFirst(),
                            formatter: (value, index, item) => item.event?.name,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "amount",
                            label: this.$t('projects::implements.amount').ucFirst(),
                            formatter: (value, index, item) => item.event?.amount?.printCurrency('HUF', this.locale),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "implement_parts",
                            label: this.$tc('projects::implement_parts.implement_part', 2).ucFirst(),
                            formatter: (value, index, item) => item.event?.implement_parts?.map(part => part.name).join(' | '),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "realized_at",
                            label: this.$t('projects::implements.columns.realized_at').ucFirst(),
                            formatter: (value) => value && value.printDate(this.locale),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        },
                    ];
                case 'interactions::interactions.interactions.contract':
                    return [
                        {
                            key: "status",
                            label: '',
                            thClass: '',
                            sortable: false
                        },
                        {
                            key: "code",
                            label: this.$t('projects::contracts.columns.code').ucFirst(),
                            formatter: (value, index, item) => item.event?.code,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "title",
                            label: this.$t('projects::contracts.columns.title').ucFirst(),
                            formatter: (value, index, item) => item?.event?.translation_key ? this.$t(item.event.translation_key).ucFirst() : item.event?.title,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "contract_type",
                            label: this.$t('projects::contracts.relationships.contract_type').ucFirst(),
                            formatter: (value, index, item) => item.event?.contract_type?.translation_key ? this.$t(item.event.contract_type.translation_key).ucFirst() : item.event?.contract_type_id,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "amount",
                            label: this.$t('projects::contracts.columns.amount').ucFirst(),
                            formatter: (value, index, item) => item.event?.amount?.printCurrency('HUF', this.locale),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "file",
                            label: this.$t('projects::contracts.relationships.file').ucFirst(),
                            formatter: (value, index, item) => item.event?.file,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "signed_file",
                            label: this.$t('projects::contracts.relationships.signed_file').ucFirst(),
                            formatter: (value, index, item) => item.event?.signed_file,
                            thClass: '',
                            sortable: true
                        },
                        /*{
                            key: "deadline",
                            label: this.$t('projects::contracts.columns.deadline').ucFirst(),
                            formatter: (value, index, item) => item.event?.deadline?.printDate(this.locale),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "validity",
                            label: this.$t('projects::contracts.validity').ucFirst(),
                            formatter: (value, index, item) => [item.event?.start?.printDate(this.locale), item.event?.end?.printDate(this.locale)].filter(str => str)?.join(' - '),
                            thClass: '',
                            sortable: true
                        },*/
                        /*{
                            key: "sent_at",
                            label: this.$t('projects::contracts.columns.sent_at').ucFirst(),
                            formatter: (value, index, item) => item.event?.sent_at?.printDate(this.locale),
                            thClass: '',
                            sortable: true
                        },*/
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        },
                    ]
                case 'interactions::interactions.interactions.offer':
                    return [
                        {
                            key: "accepted",
                            label: '', //this.$t('projects::offers.columns.accepted').ucFirst(),
                            tdClass: 'text-center',
                            formatter: (value, index, item) => item.event ? (item.event.accepted === true ? true : (item.event.assessed === true ? false : null)) : value
                        },
                        {
                            key: "event",
                            label: this.$t('projects::project_step_interactions.relationships.event').ucFirst(),
                            formatter: (value) => value ? value.name : value,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "code",
                            label: this.$t('projects::offers.columns.code').ucFirst(),
                            formatter: (value, index, item) => item.event ? item.event.code : value
                        },
                        {
                            key: "file",
                            label: this.$t('projects::offers.relationships.file').ucFirst(),
                            tdClass: 'text-center',
                            formatter: (value, index, item) => item.event ? item.event.file : value
                        },
                        {
                            key: "sent_at",
                            label: this.$t('projects::offers.columns.sent_at').ucFirst(),
                            formatter: (value, index, item) => item.event ? item.event.sent_at?.printDate(this.locale, {dateStyle: 'short', timeStyle: 'short'}) : value
                        },
                        /*{
                            key: "expiration",
                            label: this.$t('projects::offers.columns.expiration').ucFirst(),
                            formatter: (value, index, item) => item.event ? item.event.expiration?.printDate(this.locale, {dateStyle: 'short', timeStyle: 'short'}) : value
                        },*/
                        {
                            key: "amount",
                            label: this.$t('projects::offers.columns.amount').ucFirst(),
                            formatter: (value, index, item) => item.event ? item.event.amount?.printCurrency() : value
                        },
                        /*{
                            key: "meetings",
                            label: this.$t('projects::offers.relationships.meetings').ucFirst(),
                            tdClass: 'text-center',
                            formatter: (value, index, item) => item.event ? item.event.meetings?.map(m => m.name)?.join(', ') : null
                        },*/
                        {
                            key: "notification",
                            label: this.$t('projects::project_step_interactions.columns.notification').ucFirst(),
                            formatter: (value) => value?.truncate(40)
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        }
                    ];
                case 'interactions::interactions.interactions.task':
                    return [
                        {
                            key: "name",
                            label: this.$t('tasks::tasks.columns.name').ucFirst(),
                            formatter: (value, index, item) => item.event?.translation_key ? this.$t(item.event.translation_key).ucFirst() : item.event?.name,
                            thClass: '',
                            tdClass: 'font-weight-bold',
                            sortable: true
                        },
                        {
                            key: "status",
                            label: this.$t('tasks::tasks.relationships.status').ucFirst(),
                            formatter: (value, index, item) => item.event ? item.event?.status : item.event?.status_id,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "priority",
                            label: this.$t('tasks::tasks.relationships.priority').ucFirst(),
                            formatter: (value, index, item) => item.event ? item.event?.priority : item.event?.priority_id,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "type",
                            label: this.$t('tasks::tasks.relationships.type').ucFirst(),
                            formatter: (value, index, item) => item.event?.type ? (item.event.type.translation_key ? this.$t(item.event.type.translation_key) : item.event.type.name) : item.event?.type_id,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "deadline",
                            label: this.$t('tasks::tasks.columns.deadline').ucFirst(),
                            formatter: (value, index, item) => item.event?.deadline?.printDate(),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        }
                    ];
                case 'interactions::interactions.interactions.invoicing':
                    return [
                        {
                            key: "code",
                            label: this.$t('invoices::invoices.columns.code').ucFirst(),
                            formatter: (value, index, item) => item.event?.code,
                            thClass: '',
                            tdClass: 'font-weight-bold',
                            sortable: true
                        },
                        /*{
                            key: "fee_requester_id",
                            label: this.$t('invoices::invoices.relationships.fee_requester').ucFirst(),
                            formatter: (value, index, item) => item.event?.fee_requester?.code || item.event?.fee_requester_id,
                            thClass: '',
                            tdClass: 'font-weight-bold',
                            sortable: true
                        },*/
                        {
                            key: "target",
                            label: this.$t('invoices::invoices.relationships.target').ucFirst(),
                            thClass: '',
                            formatter: (value, index, item) => item.event?.target?.name,
                            tdClass: 'font-weight-bold',
                            sortable: true
                        },
                        {
                            key: "type_id",
                            label: this.$t('invoices::invoices.relationships.type').ucFirst(),
                            formatter: (value, index, item) => item.event?.type ? (item.event.type.translation_key ? this.$t(item.event.type.translation_key) : item.event.type.name) : item.event?.type_id,
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "amount",
                            label: this.$t('invoices::invoices.amount').ucFirst(),
                            formatter: (value, index, item) => item.event?.amount?.printCurrency('HUF', this.locale),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "sent_at",
                            label: this.$t('invoices::invoices.columns.sent_at').ucFirst(),
                            formatter: (value, index, item) => item.event?.sent_at?.printDate(),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "settled_at",
                            label: this.$t('invoices::invoices.columns.settled_at').ucFirst(),
                            formatter: (value, index, item) => item.event?.settled_at?.printDate(),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "canceled_at",
                            label: this.$t('invoices::invoices.columns.canceled_at').ucFirst(),
                            formatter: (value, index, item) => item.event?.canceled_at?.printDate(),
                            thClass: '',
                            sortable: true
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        }
                    ];
                default:
                    return [
                        {
                            key: "successful",
                            label: this.$t('projects::project_step_interactions.columns.successful').ucFirst(),
                        },
                        {
                            key: "realized_at",
                            label: this.$t('projects::project_step_interactions.columns.realized_at').ucFirst(),
                            formatter: (value) => value ? value.printDate() : null
                        },
                        {
                            key: "notification",
                            label: this.$t('projects::project_step_interactions.columns.notification').ucFirst(),
                        },
                        {
                            key: "actions",
                            label: this.$t('base.actions').ucFirst(),
                            thClass: '',
                            tdClass: '',
                            sortable: false
                        },
                    ];

            }
        },

        relations: function () {
            switch (this.interaction?.translation_key) {
                case 'interactions::interactions.interactions.reminder':
                    return ['event', 'event.types', 'event.remindables']
                case 'interactions::interactions.interactions.meeting':
                    return ['event', 'event.user', 'event.users']
                case 'interactions::interactions.interactions.offer':
                    return ['event', 'event.file', 'event.meetings']
                case 'interactions::interactions.interactions.implement':
                    return ['event', 'event.implementParts']
                case 'interactions::interactions.interactions.contract':
                    return ['event', 'event.contractType', 'event.file', 'event.signedFile']
                case 'interactions::interactions.interactions.task':
                    return ['event', 'event.type', 'event.status', 'event.priority']
                case 'interactions::interactions.interactions.invoicing':
                    return ['event', 'event.type', 'event.feeRequester', 'event.target', 'event.items'];
                default:
                    return []
            }
        },
    },

    methods: {
        fetch: function () {
            this.$store.dispatch('projectStepInteraction/byProject', { project_id: this.project.id, interaction_id: this.interaction?.id, with: this.relations })
        },

        remove: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('projectStepInteraction/destroy', id).then(() => {
                        this.$store.dispatch('projectStepInteraction/counted', { project_step_id: this.projectStep.id })
                    })
                }
            });
        },

        select: function (item) {
            this.selected = item
        },

        setClose: function (close) {
            this.close = close
        },

        cancelMeeting: function (id) {
            this.$store.dispatch('meeting/cancel', id).then(() => this.updated++);
        },

        realizeMeeting: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.what_happened').ucFirst(),
                text: this.$t('interactions::meetings.how_the_trial_ended').ucFirst(),
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-success',
                    denyButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.successful').ucFirst(),
                denyButtonText: this.$t('base.failed').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('meeting/realize', { id: id, data: { realized_at: (new Date()).formattedDate(), successful: true} }).then(() => this.updated++);
                }
                if (result.isDenied) {
                    this.$store.dispatch('meeting/realize', { id: id, data: { realized_at: (new Date()).formattedDate(), successful: false} }).then(() => this.updated++);
                }
            });
        },

        realizeImplementing: function (id) {
            this.$store.dispatch('implement/realize', id).then(() => this.updated++);
        },

        sendOffer: function (offer) {
            offer.sent_at = (new Date()).formattedDate();
            this.$store.dispatch('offer/update', offer).then(() => this.updated++);
        },

        answerOffer: function (offer) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.what_happened').ucFirst(),
                text: this.$t('projects::offers.customer_response').ucFirst(),
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-success',
                    denyButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.accepted').ucFirst(),
                denyButtonText: this.$t('base.refused').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    offer.assessed = true;
                    offer.accepted = true;
                    this.$store.dispatch('offer/update', offer).then(() => this.updated++);
                }
                if (result.isDenied) {
                    offer.assessed = true;
                    offer.accepted = false;
                    this.$store.dispatch('offer/update', offer).then(() => this.updated++);
                }
            });
        },

        sendContract: function (id) {
            return this.$store.dispatch(`contract/sent`, id).then(() => this.updated++);
        },

        signContract: function (id) {
            return this.$store.dispatch(`contract/signed`, id).then(() => this.updated++);
        },

        closeContract: function (id) {
            return this.$store.dispatch(`contract/closed`, id).then(() => this.updated++);
        },

        rejectContract: function (id) {
            return this.$store.dispatch(`contract/rejected`, id).then(() => this.updated++);
        },

        cancelContract: function (id) {
            return this.$store.dispatch(`contract/canceled`, id).then(() => this.updated++);
        },

        settleInvoice: function (id) {
            return this.$store.dispatch('invoice/settle', id).then(() => this.updated++);
        },

        sendInvoice: function (id) {
            return this.$store.dispatch('invoice/send', id).then(() => this.updated++);
        },

        cancelInvoice: function (id) {
            return this.$store.dispatch('invoice/cancel', id).then(() => this.updated++);
        },
    },

    watch: {
        project: {
            immediate: true,
            handler: function () {
                this.fetch()
            },
        },

        interaction: {
            immediate: true,
            handler: function () {
                this.fetch()
            },
        },

        updated: {
            handler: function () {
                this.fetch();
                this.$emit('refresh');
            },
        }
    }
})
</script>

<template>
    <b-overlay :show="loading" :key="updated">
        <div class="row">
            <div class="col">
                <simple-table :items="projectStepInteractions" :fields="fields">
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.reminder'" #is_active="{formatted}">
                        <i v-if="formatted" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.offer'" #file="{formatted, item}">
                        <template v-if="formatted">
                            <file-private-viewer model="offer" :id="item.event_id" :file-id="formatted.id">
                                <template #default="{ click }">
                                    <i class="fas fa-file-download text-primary" @click="click"></i>
                                </template>
                            </file-private-viewer>
                        </template>
                        <template v-else-if="item.event?.file_link">
                            <a :href="item.event.file_link" target="_blank">
                                <i class="fas fa-link"></i>
                            </a>
                        </template>
                    </template>
                    <template v-else-if="interaction.translation_key === 'interactions::interactions.interactions.contract'" #file="{ formatted, item }">
                        <template v-if="formatted">
                            <file-private-viewer model="contract" :id="item.event_id" :file-id="formatted.id">
                                <template #default="{ click }">
                                    <i class="fas fa-file-pdf text-primary" @click="click"></i>
                                </template>
                            </file-private-viewer>
                        </template>
                        <template v-else-if="item.event?.file_link">
                            <a :href="item.event.file_link" target="_blank">
                                <i class="fas fa-link"></i>
                            </a>
                        </template>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.contract'" #signed_file="{ formatted, item }">
                        <template v-if="formatted">
                            <file-private-viewer model="contract" :id="item.event_id" :file-id="formatted.id">
                                <template #default="{ click }">
                                    <i class="fas fa-file-pdf text-primary" @click="click"></i>
                                </template>
                            </file-private-viewer>
                        </template>
                        <template v-else-if="item.event?.signed_file_link">
                            <a :href="item.event.signed_file_link" target="_blank">
                                <i class="fas fa-link"></i>
                            </a>
                        </template>
                    </template>
                    <template v-else-if="interaction.translation_key === 'interactions::interactions.interactions.offer'" #event="{ formatted }">
                        {{ formatted }}
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.offer'" #accepted="{formatted}">
                        <i v-if="formatted === true" class="fas fa-thumbs-up text-success"></i>
                        <i v-else-if="formatted === false" class="fas fa-thumbs-down text-danger"></i>
                        <i v-else class="fas fa-question text-primary"></i>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.offer'" #meetings="{value, formatted}">
                        <template v-if="formatted?.length">
                            <span v-for="(meeting, key) in formatted" :title="meeting" :key="key">
                                {{ meeting }}
                            </span>
                        </template>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.implement'" #implementers="{ formatted }">
                        <div class="avatar-group">
                            <div v-for="(user, index) in formatted" :key="index" class="avatar-group-item">
                                <a v-if="user.user.image && user.user.image.path" href="javascript: void(0);" class="d-inline-block">
                                    <img :src="user.user.image.path"
                                         :alt="user.user.name"
                                         :title="user.user.name"
                                         class="rounded-circle avatar-sm">
                                </a>
                                <div v-else class="avatar-sm cursor-pointer">
                                    <span :title="user.user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.user.name.firstLetters() }} </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.meeting'" #user="{ formatted }">
                        <div class="avatar-group" v-if="formatted">
                            <div class="avatar-group-item">
                                <a v-if="formatted?.image && formatted?.image.path" href="javascript: void(0);" class="d-inline-block">
                                    <img :src="formatted?.image.path"
                                         :alt="formatted?.name"
                                         :title="formatted?.name"
                                         class="rounded-circle avatar-sm">
                                </a>
                                <div v-else class="avatar-sm cursor-pointer">
                                    <span :title="formatted?.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ formatted?.name.firstLetters() }} </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.meeting'" #users="{ formatted }">
                        <div class="avatar-group">
                            <div v-for="(user, index) in formatted" :key="index" class="avatar-group-item">
                                <a v-if="user.image && user.image.path" href="javascript: void(0);" class="d-inline-block">
                                    <img :src="user.image.path"
                                         :alt="user.name"
                                         :title="user.name"
                                         class="rounded-circle avatar-sm">
                                </a>
                                <div v-else class="avatar-sm cursor-pointer">
                                    <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.task'" #name="{ formatted }">
                        <span>{{ formatted }}</span>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.task'" #status="{ formatted }">
                        <div v-if="formatted" :style="{color: formatted.color}" :title="formatted.translation_key ? $t(formatted.translation_key).ucFirst() : formatted.name">
                            <i :class="formatted.icon"></i>
                        </div>
                    </template>
                    <template v-else-if="interaction.translation_key === 'interactions::interactions.interactions.offer'" #status="{ item }">
                        <i v-if="item.event?.accepted === false" class="text-danger fas fa-times"></i>
                        <i v-else-if="item.event?.accepted === true" class="text-success fas fa-check"></i>
                        <i v-else-if="item.event?.sent_at" class="text-primary fas fa-mail-bulk" :title="item.event?.sent_at?.printDate(locale)"></i>
                    </template>
                    <template v-else-if="interaction.translation_key === 'interactions::interactions.interactions.meeting'" #status="{ item }">
                        <i v-if="item.event?.successful" class="fas fa-check text-success"></i>
                        <i v-else-if="item.event?.successful === false" class="fas fa-times text-danger"></i>
                        <i v-else-if="item.event?.canceled_at !== null" class="fas fa-calendar-times text-danger"></i>
                    </template>
                    <template v-else-if="interaction.translation_key === 'interactions::interactions.interactions.contract'" #status="{ item }">
                        <i v-if="item.event?.closed_at" class="fas fa-lock text-success" :title="item.event.closed_at.printDate(locale)"></i>
                        <i v-else-if="item.event?.signed_at" class="fas fa-signature text-success" :title="item.event.signed_at.printDate(locale)"></i>
                        <i v-else-if="item.event?.canceled_at" class="fas fa-times text-danger" :title="item.event.canceled_at.printDate(locale)"></i>
                        <i v-else-if="item.event?.rejected_at" class="fas fa-ban text-danger" :title="item.event.rejected_at.printDate(locale)"></i>
                        <i v-else-if="item.event?.sent_at" class="fas fa-ban text-primary" :title="item.event.sent_at.printDate(locale)"></i>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.task'" #priority="{ formatted }">
                        <span v-if="formatted?.icon" :style="{color: formatted.color}" class="me-2">
                            <i :class="formatted.icon"></i>
                        </span>
                        <status-badge :bg-color="formatted?.color">{{ formatted?.translation_key ? $t(formatted.translation_key) : formatted?.name }}</status-badge>
                    </template>
                    <template v-if="interaction.translation_key === 'interactions::interactions.interactions.offer'" #sent_at="{item, formatted}">
                        <span>{{ formatted }}</span>
                        <template v-if="item.event?.expiration">
                            <br/>
                            <small class="text-muted">{{ $t('projects::offers.columns.expiration').ucFirst() }}</small>
                            <br/>
                            <small>{{ item.event.expiration?.printDate(locale, {dateStyle: 'short', timeStyle: 'short'}) }}</small>
                        </template>
                    </template>
                    <template #actions="{ item, edit, close }">
                        <router-link v-if="item?.event?.id && link" :to="$t(link, {id: item?.event?.id}).rmValidation()" @click="$emit('close')">
                            <i :title="$t('base.edit').ucFirst()" class="fas fa-edit text-primary"></i>
                        </router-link>
                        <template v-if="interaction.translation_key === 'interactions::interactions.interactions.implement'">
                            <i v-if="!item.event?.realized_at" class="fas fa-check text-success cursor-pointer ms-3" :title="$t('projects::implements.realized').ucFirst()" @click="realizeImplementing(item?.event?.id)"></i>
                        </template>
                        <template v-if="interaction.translation_key === 'interactions::interactions.interactions.meeting'">
                            <i v-if="item?.event?.realized_at === null && item?.event?.canceled_at === null" class="fas fa-calendar-times text-danger cursor-pointer ms-3" :title="$t('base.canceled').ucFirst()" @click="cancelMeeting(item?.event?.id)"></i>
                        </template>
                        <template v-if="interaction.translation_key === 'interactions::interactions.interactions.meeting'">
                            <i v-if="item?.event?.realized_at === null && item?.event?.canceled_at === null" class="fas fa-calendar-check text-success cursor-pointer ms-3" :title="$t('base.realized').ucFirst()" @click="realizeMeeting(item?.event?.id)"></i>
                        </template>
                        <template v-if="interaction.translation_key === 'interactions::interactions.interactions.offer'">
                            <i v-if="!item.event?.sent_at" class="fas fa-mail-bulk text-success cursor-pointer ms-3" @click="sendOffer(item.event)"></i>
                            <i v-if="!item.event?.assessed" class="fas fa-comments text-success cursor-pointer ms-3" @click="answerOffer(item.event)"></i>
                        </template>
                        <template v-if="interaction.translation_key === 'interactions::interactions.interactions.contract'">
                            <i v-if="!item.event?.sent_at" :title="$t('base.sent').ucFirst()" class="fas fa-mail-bulk text-success cursor-pointer ms-3" @click="sendContract(item.event.id)"></i>
                            <i v-else-if="!item.event?.signed_at" :title="$t('base.signed').ucFirst()" class="fas fa-signature text-success cursor-pointer ms-3" @click="signContract(item.event.id)"></i>
                            <i v-else-if="!item.event?.closed_at" :title="$t('base.closed').ucFirst()" class="fas fa-lock text-success cursor-pointer ms-3" @click="closeContract(item.event.id)"></i>
                            <i v-if="item.event?.signed_at && !item.event?.closed_at && !item.event?.canceled_at" :title="$t('base.canceled').ucFirst()" class="fas fa-times text-danger cursor-pointer ms-3" @click="cancelContract(item.event.id)"></i>
                            <i v-if="!item.event?.signed_at && item.event?.sent_at && !item.event?.rejected_at" :title="$t('base.rejected').ucFirst()" class="fas fa-ban text-danger cursor-pointer ms-3" @click="rejectContract(item.event.id)"></i>
                        </template>
                        <template v-if="interaction.translation_key === 'interactions::interactions.interactions.invoicing'">
                            <i v-if="!item.event?.sent_at" class="fas fa-mail-bulk text-success cursor-pointer ms-3" @click="sendInvoice(item?.event?.id)" :title="$t('invoices::invoices.sent').ucFirst()"></i>
                            <i v-if="!item.event?.canceled_at " class="fas fa-times text-danger cursor-pointer ms-3" @click="cancelInvoice(item?.event?.id)" :title="$t('invoices::invoices.canceled').ucFirst()"></i>
                            <i v-if="!item.event?.settled_at " class="fas fa-money-bill-wave text-success cursor-pointer ms-3" @click="settleInvoice(item?.event?.id)" :title="$t('invoices::invoices.settled').ucFirst()"></i>
                        </template>
                        <i v-if="!item.event_type" class="fas fa-edit text-primary cursor-pointer" @click="select(item);edit(item);setClose(close)"></i>
                        <i :title="$t('base.delete').ucFirst()" class="fas fa-trash text-danger cursor-pointer ms-3" @click="remove(item?.id)"></i>
                    </template>
                    <template #successful="{item}">
                        <i v-if="item.successful === true" class="fas fa-thumbs-up text-success"></i>
                        <i v-else-if="item.failed === true" class="fas fa-thumbs-down text-danger"></i>
                    </template>
                    <template #default>
                        <project-step-interaction-form :project-step-interaction="selected" @saved="close" :project-step="projectStep" :interaction="interaction" :only-successful="true">
                            <template #buttons>
                                <b-button class="ms-2" variant="secondary" @click="close">{{ $t('base.close').ucFirst() }}</b-button>
                            </template>
                        </project-step-interaction-form>
                    </template>
                </simple-table>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>

</style>
