<script>
import FileViewer from "./file-viewer.vue";

export default {
    name: "multiple-file",
    components: {FileViewer},

    emits: ['update:modelValue', 'update:files'],

    props: {
        modelValue: {
            required: true
        },

        files: {
            type: Array,
            default: () => { return []; }
        },

        placeholder: {
            type: String
        },

        disabled: {
            type: Boolean,
            default: false
        },
    },

    data: function () {
        return {
            upload: null,
            open: 1,
            selected: null
        }
    },

    methods: {
        triggerFileInput: function () {
            this.$refs.input.click()
        },

        setFile: function (event) {
            this.$emit('update:modelValue', event.target.files)
        },

        remove: function (index) {
            const model = JSON.parse(JSON.stringify(this.files)) || []

            model.splice(index, 1)

            this.$emit('update:files', model)
        },

        icon: function (file) {
            switch (file?.extension) {
                case 'pdf':
                    return 'fa-file-pdf text-danger';
                case 'png':
                case 'jpeg':
                case 'jpg':
                    return 'fa-file-image text-warning';
                case 'docx':
                case 'doc':
                    return 'fa-file-word';
                case 'xls':
                case 'xlsx':
                    return 'fa-file-excel text-success';
                default:
                    return 'fa-file text-secondary';
            }
        },

        uploadIcon: function (file) {
            switch (file?.type) {
                case 'application/pdf':
                    return 'fa-file-pdf text-danger';
                case 'image/png':
                case 'image/jpeg':
                case 'image/jpg':
                    return 'fa-file-image text-warning';
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'fa-file-word';
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return 'fa-file-excel text-success';
                default:
                    return 'fa-file text-secondary';
            }
        },
    }
}
</script>

<template>
    <div class="w-100">
        <div class="row">
            <div class="col">
                <div class="w-100 mb-3">
                    <div class="d-inline me-3" v-for="(file, index) in files" :key="index">
                        <span class="cursor-pointer me-1" @click="selected = file.storage_path; open++">
                            <i class="fas fa-file text-primary me-1" :class="icon(file)"></i>
                            <small>{{ file?.name }}</small>
                        </span>
                        <i class="fas fa-trash text-danger cursor-pointer font-size-10" @click="remove(index)"></i>
                    </div>
                </div>
                <div class="w-100">
                    <div class="d-inline me-3" v-for="(file, index) in modelValue" :key="index">
                        <i class="fas fa-file text-primary me-1" :class="uploadIcon(file)"></i>
                        <small>{{ file?.name }}</small>
                    </div>
                    <b-button variant="outline-success" size="sm" @click="triggerFileInput()">
                        <i class="fas fa-plus"></i>
                    </b-button>
                </div>
            </div>
        </div>
        <input
            ref="input"
            class="d-none"
            type="file"
            multiple
            @change="setFile($event)"
        />
        <file-viewer :click-event="open" :path="selected"></file-viewer>
    </div>
</template>

<style scoped>

</style>
