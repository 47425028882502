<script>
import TransitionToggle from "../transitions/toggle.vue";
import StatusBadge from "./status-badge.vue";

export default {
    name: "status-selector",

    emits: ['update:modelValue'],

    components: {StatusBadge, TransitionToggle},

    props: {
        modelValue: {},

        statuses: {
            type: Array,
            default: () => { return [] }
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            opened: false,
            value: null
        };
    },

    methods: {
        open: function () {
            if (this.disabled) {
                return;
            }

            this.opened = true;
        },

        close: function () {
            this.opened = false;
        },

        select: function (id) {
            this.value = this.statuses.find(status => status.id === id);
        },

        changeStatus: function (status) {
            this.$emit('update:modelValue', status.id);
        },
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (value) {
                this.select(value);
            },
        },

        statuses: {
            immediate: true,
            deep: true,
            handler: function (arr) {
                if (Array.isArray(arr) && this.modelValue) {
                    this.select(this.modelValue);
                }
            },
        }
    }
}
</script>

<template>
<div class="" @mouseleave="close">
    <span class="d-inline-flex align-items-center cursor-pointer" @click="open">
        <span v-if="value?.icon" :style="{color: value.color}" class="font-size-20 me-2">
            <i :class="value.icon"></i>
        </span>
        <status-badge class="form-control-height font-size-16 d-inline-flex align-items-center" :bg-color="value?.color">{{ value?.translation_key ? $t(value.translation_key).ucFirst() : value?.name }}</status-badge>
    </span>
    <transition-toggle>
        <div class="p-1">
            <div v-if="opened" class="p-2 status-list position-absolute bg-light border rounded">
                <ul class="list-unstyled">
                    <template v-for="(status, index) in statuses" :key="index">
                        <li class="cursor-pointer mb-1" v-if="value.id !== status.id" @click="changeStatus(status)">
                            <span v-if="status?.icon" :style="{color: status.color}" class="me-2">
                                <i :class="status.icon"></i>
                            </span>
                            <status-badge :bg-color="status?.color">{{ status?.translation_key ? $t(status.translation_key).ucFirst() : status?.name }}</status-badge>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </transition-toggle>
</div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

.status-list {
    z-index: 1;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
    border-width: 1px;
    border-style: solid;
    border-color: $primary
}

.form-control-height {
    height: 30px;
    border-radius: 50px;
}
</style>