<script>

export default {
    name: "tree",

    emits: ['select'],

    props: {
        items: {
            type: Array,
            required: true
        },

        prop: {
            type: String,
            required: true
        },

        labelField: {
            type: String,
            default: 'name'
        },

        formatter: {
            type: Function
        },

        first: {
            type: Boolean,
            default: true
        },

        closable: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            selected: {},
            closed: false
        };
    },

    methods: {
        select: function (item, index) {
            if (Number.isInteger(index)) this.selected[index] = !this.selected[index];
            this.$emit('select', item, Number.isInteger(index) ? this.selected[index] : index);
        },
    }
}
</script>

<template>
    <ul v-if="!closed" class="list-unstyled" :class="{'border rounded bg-white mb-0 p-3 position-relative': first}">
        <li v-for="(item, index) in items" :key="index">
            <div class="d-block cursor-pointer" @click="select(item, index)">
                <span v-if="!first" class="me-2">
                    <i class="mdi mdi-subdirectory-arrow-right"></i>
                </span>
                <span>{{ formatter ? formatter(item) : item[labelField] }}</span>
                <span v-if="selected[index]" class="text-success ms-2">
            <i class="fas fa-check"></i>
        </span>
            </div>
            <tree class="ps-3" v-if="item[prop]?.length" :items="item[prop]" :prop="prop" @select="select" :first="false"></tree>
        </li>
        <span class="cursor-pointer p-1 position-absolute top-right" v-if="first && closable" @click="closed = true">
            <i class="fas fa-times"></i>
        </span>
    </ul>
</template>

<style scoped>
.top-right {
    top: 1px;
    right: 5px;
}
</style>