<script>
import Swal from "sweetalert2";

export default {
    name: 'contact-card',

    props: {
        contact: {
            type: Object,
            required: true
        }
    },

    computed: {
        position: function () {
            return this.contact && this.contact.position
                ? this.contact.position.translation_key ? this.$t(this.contact.position.translation_key) : this.contact.position.name
                : null
        },

        letter: function () {
            return this.contact && this.contact.firstname ? this.contact.firstname[0] : '?'
        },
    },

    methods: {
        select: function () {
            this.$store.dispatch('contact/select', this.contact.id)
        },

        remove: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('contact/destroy', this.contact.id)
                }
            });
        },

        createDecisionMaker: function () {
            this.$store.dispatch('contact/createDecisionMaker', this.contact.id);
        },
    }
}
</script>

<template>
    <div class="card overflow-hidden">
        <div class="card-body">
            <div v-if="contact.decision_maker_exists === true" class="position-absolute bg-soft-primary card-corner bg-gradient" :title="$t('partners::decision_makers.decision_maker').ucFirst()">
                <i class="fas fa-user-tie text-primary"></i>
            </div>
            <div class="dropdown float-end">
                <a
                    aria-haspopup="true"
                    class="text-muted dropdown-toggle font-size-16"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                >
                    <i class="bx bx-dots-horizontal-rounded"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#" @click="select">{{ $t('base.edit').ucFirst() }}</a>
                    <a v-if="contact.decision_maker_exists !== true" class="dropdown-item" href="#" @click="createDecisionMaker">{{ $tc('partners::decision_makers.decision_maker', 1).ucFirst() }}</a>
                    <a class="dropdown-item" href="#" @click="remove">{{ $t('base.delete').ucFirst() }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div>
                    <img
                        v-if="contact.image"
                        :alt="contact.name"
                        :title="contact.name"
                        class="avatar-md rounded-circle img-thumbnail"
                        :src="contact.image"
                    />
                    <div v-else class="avatar-md">
                        <span class="avatar-title rounded-circle img-thumbnail text-white font-size-26" :class="{'bg-primary': contact.primary, 'bg-secondary': !contact.primary}"> {{ letter }} </span>
                    </div>
                </div>
                <div class="flex-1 ms-3">
                    <h5 class="font-size-16 mb-1">
                        <span class="text-dark">{{ contact.name }}</span>
                    </h5>
                    <span class="badge badge-soft-success mb-0"
                    >{{ position }}</span
                    >
                </div>
            </div>
            <div class="mt-3 pt-1">
                <p class="text-muted mb-0">
                    <i
                        class="
                            mdi mdi-phone
                            font-size-15
                            align-middle
                            pe-2
                            text-primary
                          "
                    ></i>
                    {{ contact.phone }}
                </p>
                <p class="text-muted mb-0 mt-2">
                    <i
                        class="
                            mdi mdi-email
                            font-size-15
                            align-middle
                            pe-2
                            text-primary
                          "
                    ></i>
                    {{ contact.email }}
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .card-corner {
        display: flex;
        bottom: -1px;
        right: 0;
        width: 50px;
        height: 50px;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 10px;
        clip-path: polygon(0 100%, 100% 0, 100% 100%);
    }
</style>
