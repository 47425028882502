<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import MultipleSelect from "../elements/multiple-select.vue";
import Swal from "sweetalert2";
import ListFree from "../elements/list-free.vue";

export default defineComponent({
    name: "meeting-form",
    components: {ListFree, MultipleSelect},

    emits: ['saved', 'updated'],

    props: {
        meeting: {
            type: Object,
            required: true
        },

        fixed: {
            type: Boolean,
            default: false
        },

        own: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'meeting/loading',
            'states': 'meeting/states',
            'errors': 'meeting/errors',
            admin: 'auth/admin',
            locale: 'trans/locale',
            configuration: 'app/configuration',
        }),

        meetingLength: function () {
            return this.configuration?.interactions?.meeting_length;
        },

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        partnerTypes: function () {
            return [
                {
                    value: 'Appon\\Partners\\Models\\Partner',
                    name: this.$tc('partners::partners.partner', 2).ucFirst(),
                    store: 'partner'
                }
            ]
        },

        userTypes: function () {
            return [
                {
                    value: 'App\\Models\\User',
                    name: this.$tc('users.user', 2).ucFirst(),
                    store: 'user'
                }
            ]
        },

        partners: function () {
            if (!this.model.partner_type || !this.partnerStore) {
                return [];
            }

            return this.$store.getters[`${this.partnerStore}/all`]
        },

        users: function () {
            if (!this.model.user_type || !this.userStore) {
                return [];
            }

            return this.$store.getters[`${this.userStore}/all`]
        },

        closed: function () {
            return this.locked && (this.model.realized_at
                || this.model.successful
                || this.model.canceled_at
            );
        },
    },

    data: function () {
        return {
            model: {},
            partnerStore: null,
            userStore: null,
            locked: true,
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'
            const correction = {};

            if (this.model.realized_at) {
                const date = new Date(this.model.realized_at);
                correction.realized_at = date.formattedDate();
            }

            if (this.model.canceled_at) {
                const date = new Date(this.model.canceled_at);
                correction.canceled_at = date.formattedDate();
            }

            if (this.model.start) {
                const date = new Date(this.model.start);
                correction.start = date.formattedDate();
            }

            if (this.model.end) {
                const date = new Date(this.model.end);
                correction.end = date.formattedDate();
            }

            const data = Object.assign({with: ['partner', 'users', 'attendees']}, this.model, correction);

            if (!this.doNotSave) {
                return this.$store.dispatch(`meeting/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create') {
                        const pathType = this.own ? 'my-meetings' : 'meetings'
                        this.$router.push(this.$t('routes.'+'/interactions/' + pathType + '/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('meeting/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        setModel: function (model = {}) {
            const base = {
                own: this.own
            };

            if (!model.id) {
                base.is_active = true;
            }

            this.model = Object.assign({}, base, JSON.parse(JSON.stringify(model)));
        },

        setPartnerStore: function () {
            if (!this.model.partner_type) {
                this.partnerStore = null
            }

            const type = this.partnerTypes.find(t => t.value === this.model.partner_type)

            if (type) {
                this.partnerStore = type.store
            }
        },

        fetchPartners: function () {
            this.$store.dispatch(`${this.partnerStore}/all`)
        },

        setUserStore: function () {
            if (!this.model.user_type) {
                this.userStore = null
            }

            const type = this.userTypes.find(t => t.value === this.model.user_type)

            if (type) {
                this.userStore = type.store
            }
        },

        fetchUsers: function () {
            this.$store.dispatch(`${this.userStore}/all`)
        },

        answered: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.what_happened').ucFirst(),
                text: this.$t('interactions::meetings.how_the_trial_ended').ucFirst(),
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-success',
                    denyButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.successful').ucFirst(),
                denyButtonText: this.$t('base.failed').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.model.successful = true;
                    if (!this.model.realized_at) {
                        this.model.realized_at = (new Date(this.model.realized_at)).formattedDate();
                    }
                    this.save();
                }
                if (result.isDenied) {
                    this.model.successful = false;
                    if (!this.model.realized_at) {
                        this.model.realized_at = (new Date(this.model.realized_at)).formattedDate();
                    }
                    this.save();
                }
            });
        },

        canceled: function () {
            this.$store.dispatch('meeting/cancel', this.model.id);
        },

        realized: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.what_happened').ucFirst(),
                text: this.$t('interactions::meetings.how_the_trial_ended').ucFirst(),
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-success',
                    denyButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.successful').ucFirst(),
                denyButtonText: this.$t('base.failed').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('meeting/realize', { id: this.model.id, data: { realized_at: (new Date()).formattedDate(), successful: true} });
                }
                if (result.isDenied) {
                    this.$store.dispatch('meeting/realize', { id: this.model.id, data: { realized_at: (new Date()).formattedDate(), successful: false} });
                }
            });
        },

        unlock: function () {
            if (this.admin === true) {
                Swal.fire({
                    icon: 'warning',
                    title: this.$t('base.confirm_title'),
                    text: this.$t('base.confirm_message'),
                    showCancelButton: true,
                    showConfirmButton: true,
                    customClass: {
                        icon: 'text-warning border-warning',
                        confirmButton: 'btn btn-warning',
                        cancelButton: 'btn btn-warning'
                    },
                    confirmButtonText: this.$t('base.unlock').ucFirst(),
                    cancelButtonText: this.$t('base.cancel').ucFirst(),
                }).then(result => {
                    if (result.isConfirmed) {
                        this.locked = false;
                    }
                });
            }
        },

        setEnd: function () {
            if (this.meetingLength && this.model.start && !this.model.end) {
                const start = new Date(this.model.start);
                const length = this.meetingLength.split(' ');
                const number = Number(length[0]);
                const type = length[1];
                let time = start.getTime();
                let plus = 0;

                switch (type) {
                    case 'hour':
                        plus = (number*60*60*1000);
                        break;
                    case 'minute':
                        plus = (number*60*1000);
                        break;
                }

                this.model.end = (new Date(time + plus)).formattedDate();
            }
        },
    },

    watch: {
        meeting: function (value) {
            this.setModel(value)
        },

        partnerStore: function () {
            this.fetchPartners()
        },

        userStore: function () {
            this.fetchUsers()
        },
    },

    created() {
        this.setModel(this.meeting)
    }
})
</script>

<template>
    <form @submit.prevent>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div v-if="model.partner_id" class="col-12 mb-4 mb-lg-5">
                    <h3 id="contract-project" class="text-muted border-bottom pb-2 border-secondary">
                        <router-link :to="$t('routes./partners/:id(\\d+)/:type?', {id: model.partner_id, type: ''}).rmValidation()">
                            <i class="fas fa-building me-3"></i>
                            <span>{{ model?.partner?.name }}</span>
                        </router-link>
                    </h3>
                    <p v-if="model.realized_at || model.canceled_at">
                        <small class="font-size-14 ms-3 text-center" v-if="model.realized_at">
                            <i v-if="model.successful" class="fas fa-check text-success me-2"></i>
                            <i v-else-if="model.successful === false" class="fas fa-times text-danger me-2"></i>
                            <span>{{ model.realized_at.printDate(locale) }}</span>
                        </small>
                        <small class="font-size-14 ms-3 text-center" v-else-if="model.canceled_at">
                            <i class="fas fa-calendar-times text-danger me-2"></i>
                            <span>{{ model.canceled_at.printDate(locale) }}</span>
                        </small>
                    </p>
                </div>
                <div class="col-lg-3 mb-4" v-if="!fixed">
                    <label
                        :class="{'text-danger': states.title === false}"
                        class="form-label"
                        for="title"
                    >{{ $t('interactions::meetings.columns.title').ucFirst() }} *</label
                    >

                    <input
                        id="title"
                        v-model="model.title"
                        :class="{'border-danger': states.title === false}"
                        :placeholder="$t('interactions::meetings.placeholders.title').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.title === false}" class="invalid-feedback">{{ errors.title }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.subject === false}"
                        class="form-label"
                        for="subject"
                    >{{ $t('interactions::meetings.columns.subject').ucFirst() }} *</label
                    >

                    <input
                        id="subject"
                        v-model="model.subject"
                        :class="{'border-danger': states.subject === false}"
                        :placeholder="$t('interactions::meetings.placeholders.subject').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.subject === false}" class="invalid-feedback">{{ errors.subject }}</div>
                </div>
                <div class="col-lg-3 mb-4" v-if="!fixed && !model.partner">
                    <label
                        :class="{'text-danger': states.partner_type === false}"
                        class="form-label"
                        for="partner_type"
                    >{{ $t('interactions::meetings.columns.partner_type').ucFirst() }}</label>
                    <select
                        id="partner_type"
                        v-model="model.partner_type"
                        :class="{'border-danger': states.partner_type === false}"
                        class="form-select"
                        name="partner_type"
                        @change="setPartnerStore"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('interactions::meetings.placeholders.partner_type').ucFirst() }}
                        </option>
                        <option v-for="(partnerType, index) in partnerTypes" :key="index" :value="partnerType.value">{{
                                partnerType.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.partner_type === false}" class="invalid-feedback">
                        {{ errors.partner_type }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4" v-if="!fixed && !model.partner">
                    <label
                        :class="{'text-danger': states.partner_id === false}"
                        class="form-label"
                        for="partner_id"
                    >{{ $t('interactions::meetings.columns.partner_id').ucFirst() }}</label>
                    <select
                        id="partner_id"
                        v-model="model.partner_id"
                        :class="{'border-danger': states.partner_id === false}"
                        class="form-select"
                        name="partner_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('interactions::meetings.placeholders.partner_id').ucFirst() }}
                        </option>
                        <option v-for="(partner, index) in partners" :key="index" :value="partner.id">{{
                                partner.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.partner_id === false}" class="invalid-feedback">
                        {{ errors.partner_id }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4" v-if="!own && !fixed">
                    <label
                        :class="{'text-danger': states.user_type === false}"
                        class="form-label"
                        for="user_type"
                    >{{ $t('interactions::meetings.columns.user_type').ucFirst() }}</label>
                    <select
                        id="user_type"
                        v-model="model.user_type"
                        :class="{'border-danger': states.user_type === false}"
                        class="form-select"
                        name="user_type"
                        @change="setUserStore"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('interactions::meetings.placeholders.user_type').ucFirst() }}
                        </option>
                        <option v-for="(userType, index) in userTypes" :key="index" :value="userType.value">{{
                                userType.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_type === false}" class="invalid-feedback">
                        {{ errors.user_type }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4" v-if="!own && !fixed">
                    <label
                        :class="{'text-danger': states.user_id === false}"
                        class="form-label"
                        for="user_id"
                    >{{ $t('interactions::meetings.columns.user_id').ucFirst() }}</label>
                    <select
                        id="user_id"
                        v-model="model.user_id"
                        :class="{'border-danger': states.user_id === false}"
                        class="form-select"
                        name="user_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('interactions::meetings.placeholders.user_id').ucFirst() }}
                        </option>
                        <option v-for="(user, index) in users" :key="index" :value="user.id">{{
                                user.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">
                        {{ errors.user_id }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.start === false}"
                        class="form-label"
                        for="start"
                    >{{ $t('interactions::meetings.columns.start').ucFirst() }}</label
                    >
                    <input
                        id="start"
                        v-model="model.start"
                        :class="{'border-danger': states.start === false}"
                        :placeholder="$t('interactions::meetings.placeholders.start').ucFirst()"
                        class="form-control"
                        name="start"
                        type="datetime-local"
                        @change="setEnd"
                    />
                    <div :class="{'d-block': states.start === false}" class="invalid-feedback">{{ errors.start }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.end === false}"
                        class="form-label"
                        for="end"
                    >{{ $t('interactions::meetings.columns.end').ucFirst() }}</label
                    >
                    <input
                        id="end"
                        v-model="model.end"
                        :class="{'border-danger': states.end === false}"
                        :placeholder="$t('interactions::meetings.placeholders.end').ucFirst()"
                        class="form-control"
                        name="end"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.end === false}" class="invalid-feedback">{{ errors.end }}</div>
                </div>
                <div v-if="false" class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.realized_at === false}"
                        class="form-label"
                        for="realized_at"
                    >{{ $t('interactions::meetings.columns.realized_at').ucFirst() }}</label
                    >
                    <input
                        id="realized_at"
                        v-model="model.realized_at"
                        :class="{'border-danger': states.realized_at === false}"
                        :placeholder="$t('interactions::meetings.placeholders.realized_at').ucFirst()"
                        class="form-control"
                        name="realized_at"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.realized_at === false}" class="invalid-feedback">{{ errors.realized_at }}</div>
                </div>
                <div v-if="false" class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.canceled_at === false}"
                        class="form-label"
                        for="canceled_at"
                    >{{ $t('interactions::meetings.columns.canceled_at').ucFirst() }}</label
                    >
                    <input
                        id="canceled_at"
                        v-model="model.canceled_at"
                        :class="{'border-danger': states.canceled_at === false}"
                        :placeholder="$t('interactions::meetings.placeholders.canceled_at').ucFirst()"
                        class="form-control"
                        name="canceled_at"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.canceled_at === false}" class="invalid-feedback">{{ errors.canceled_at }}</div>
                </div>
                <div class="col-lg-3 mb-4">

                    <label
                        :class="{'text-danger': states.notification === false}"
                        class="form-label"
                        for="notification"
                    >{{ $t('interactions::meetings.columns.notification').ucFirst() }}</label
                    >
                    <textarea
                        id="notification"
                        v-model="model.notification"
                        :class="{'border-danger': states.notification === false}"
                        :placeholder="$t('interactions::meetings.placeholders.notification').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.notification === false}" class="invalid-feedback">
                        {{ errors.notification }}
                    </div>
                </div>
                <div v-if="false" class="col-lg-1 mb-4">
                    <label
                        :class="{'text-danger': states.successful === false}"
                        class="form-label"
                        for="is-active-input"
                    >{{ $t('interactions::meetings.columns.successful').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is-active-input"
                            v-model="model.successful"
                            :class="{'border-danger': states.successful === false}"
                            class="form-check-input"
                            name="successful"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.successful === false}" class="invalid-feedback">
                        {{ errors.successful }}
                    </div>
                </div>
                <div class="col-lg-1 mb-4">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is-active-input"
                    >{{ $t('interactions::meetings.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is-active-input"
                            v-model="model.is_active"
                            :class="{'border-danger': states.is_active === false}"
                            class="form-check-input"
                            name="is_active"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.users === false}"
                        class="form-label"
                        for="users"
                    >{{ $t('interactions::meetings.relationships.users').ucFirst() }}</label>
                    <multiple-select id="users" v-model="model.users" store="user" morph="App\Models\User"></multiple-select>
                </div>
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.attendees === false}"
                        class="form-label"
                        for="attendees"
                    >{{ $t('interactions::meetings.relationships.users').ucFirst() }}</label>
                    <list-free id="attendees" class="mb-4" v-model="model.attendees"></list-free>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12 text-center">
                    <template v-if="closed">
                        <button class="btn btn-outline-white" type="button" @click.prevent="unlock">
                            <i class="text-muted fas fa-lock font-size-30 cursor-pointer"></i>
                        </button>
                    </template>
                    <template v-else>
                        <button :title="btnText" class="btn btn-primary" type="submit" @click.prevent="save">
                            <i class="fas fa-save"></i>
                            <span class="ms-2">{{ btnText }}</span>
                        </button>
                        <slot name="buttons" :save="save"></slot>
                        <button v-if="model.id && model.realized_at === null" :title="$t('base.realized').ucFirst()" class="btn btn-success ms-2" type="submit" @click.prevent="realized">
                            <i class="fas fa-calendar-check"></i>
                            <span class="ms-2">{{ $t('base.realized').ucFirst() }}</span>
                        </button>
                        <button v-if="model.id && model.realized_at === null && model.canceled_at === null" :title="$t('base.canceled').ucFirst()" class="btn btn-danger ms-2" type="submit" @click.prevent="canceled">
                            <i class="fas fa-times"></i>
                            <span class="ms-2">{{ $t('base.canceled').ucFirst() }}</span>
                        </button>
                        <button v-if="model.id && model.successful === null && model.realized_at !== null" class="btn btn-white ms-2 border-secondary" type="submit" @click.prevent="answered">
                            <i id="check-icon" class="fas fa-check text-success"></i>
                            <i id="slash-icon" class="fas fa-slash text-dark"></i>
                            <i id="times-icon" class="fas fa-times text-danger"></i>
                            <span class="ms-1 mb-4 mb-lg-0 d-none d-lg-inline text-dark">{{ $t('base.answered').ucFirst() }}</span>
                        </button>
                    </template>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>
#check-icon {
    transform: scale(0.8) translate(12px, -4px);
}
#slash-icon {
    transform: rotate(100deg);
}
#times-icon {
    transform: scale(0.8) translate(-10px, 6px);
}
</style>
