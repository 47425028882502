<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import ProjectStepInteractionForm from "./project-step-interaction-form.vue";
import ProjectStepInteractionList from "../elements/project-step-interaction-list.vue";
import ReminderForm from "./reminder-form.vue";
import MeetingForm from "./meeting-form.vue";
import OfferForm from "./offer-form.vue";
import ContractForm from "./contract-form.vue";
import ImplementForm from "./implement-form.vue";
import TaskForm from "./tasks/task-form.vue";
import InvoiceForm from "./invoice-form.vue";
import StatusSelector from "../elements/status-selector.vue";

export default defineComponent({
    name: "project-step-form",

    components: {
        StatusSelector,
        InvoiceForm,
        TaskForm,
        ImplementForm,
        ContractForm,
        OfferForm, MeetingForm, ReminderForm, ProjectStepInteractionList, ProjectStepInteractionForm},

    emits: ['saved', 'modified'],

    props: {
        projectStep: {
            type: Object,
            required: true
        },

        fix: {
            type: Boolean,
            default: false
        },

        requiredStatus: {
            type: Number
        },

        requiredInteractions: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'projectStep/loading',
            'states': 'projectStep/states',
            'errors': 'projectStep/errors',
            projects: 'project/all',
            steps: 'step/all',
            statuses: 'step/statuses',
            step: 'step/item',
            projectStepInteraction: 'projectStepInteraction/item',
            project: 'project/item',
            reminder: 'reminder/item',
            task: 'task/item',
            meeting: 'meeting/item',
            counted: 'projectStepInteraction/counted',
            offer: 'offer/item',
            contract: 'contract/item',
            implement: 'implement/item',
            invoice: 'invoice/item',
            admin: 'auth/admin'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        disabled: function () {
            // return !!this.project?.closed_at // && !this.admin
            return !!this.$store.state.project.item?.closed_at // && !this.admin
        },
    },

    data: function () {
        return {
            model: {},
            projectStepInteractionModal: false,
            projectStepInteractionList: false,
            reminderModal: false,
            meetingModal: false,
            offerModal: false,
            implementModal: false,
            taskModal: false,
            contractModal: false,
            invoicingModal: false,
            interaction: {},
            notification: null,
        }
    },

    methods: {
        createInteraction: function (interaction) {
            this.interaction = interaction

            this.$store.dispatch('projectStepInteraction/setItem' ,{
                project_step_id: this.model.id,
                interaction_id: interaction.id
            })

            switch (interaction.translation_key) {
                case 'interactions::interactions.interactions.reminder':
                    this.reminderModal = true;
                    break;
                case 'interactions::interactions.interactions.meeting':
                    this.meetingModal = true;
                    break;
                case 'interactions::interactions.interactions.offer':
                    this.offerModal = true;
                    break;
                case 'interactions::interactions.interactions.contract':
                    this.contractModal = true;
                    break;
                case 'interactions::interactions.interactions.implement':
                    this.implementModal = true;
                    break;
                case 'interactions::interactions.interactions.task':
                    this.taskModal = true;
                    break;
                case 'interactions::interactions.interactions.invoicing':
                    this.invoicingModal = true;
                    break;
                default:
                    this.projectStepInteractionModal = true
                    break;
            }
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`projectStep/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                this.$emit('modified')
                if (method === 'create' && !this.fix) {
                    this.$router.push(this.$t('routes.'+'/project-steps/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        saveReminder: function (newReminder) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.reminder')

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                reminder: newReminder
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(() => {
                this.reminderModal = false
                this.$store.dispatch('projectStepInteraction/setItem', {})
                this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                this.$store.dispatch('reminder/setItem', {});
                this.$emit('modified')
            })
        },

        saveMeeting: function (newMeeting) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.meeting')

            console.log(newMeeting);

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                meeting: Object.assign({}, newMeeting, {
                    partner_type: this.project?.target_type,
                    partner_id: this.project?.target_id
                })
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(() => {
                this.meetingModal = false
                this.$store.dispatch('projectStepInteraction/setItem', {})
                this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                this.$store.dispatch('meeting/setItem', {});
                this.$emit('modified')
            })
        },

        saveOffer: function (newOffer) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.offer')

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                notification: this.notification,
                offer: Object.assign({}, newOffer, {
                    project_id: this.project?.id
                })
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(() => {
                this.offerModal = false
                this.$store.dispatch('projectStepInteraction/setItem', {})
                this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                this.$store.dispatch('offer/setItem', {});
                this.$emit('modified')
            })
        },

        saveContract: function (newContract) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.contract')

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                contract: Object.assign({}, newContract, {
                    project_id: this.project?.id
                })
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(() => {
                this.contractModal = false
                this.$store.dispatch('projectStepInteraction/setItem', {})
                this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                this.$store.dispatch('contract/setItem', {});
                this.$emit('modified')
            })
        },

        saveImplementing: function (newImplementing) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.implement')

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                implement: Object.assign({}, newImplementing, {
                    project_id: this.project?.id
                })
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(() => {
                this.implementModal = false
                this.$store.dispatch('projectStepInteraction/setItem', {})
                this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                this.$store.dispatch('implement/setItem', {});
                this.$emit('modified')
            })
        },

        saveTask: function (newTask) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.task')

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                task: Object.assign({
                    client_type: this.project?.target_type,
                    client_id: this.project?.target_id,
                    inducers: [{
                        inducer_type: 'Appon\\Projects\\Models\\Project',
                        inducer_id: this.project.id,
                    }],
                }, newTask)
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(response => {
                if (data.task.reminder) {
                    const reminder = Object.assign({}, data.task.reminder)
                    reminder.reference_type = response.event_type
                    reminder.reference_id = response.event_id
                    this.$store.dispatch('reminder/create', reminder).then(() => {
                        this.taskModal = false;
                        this.$store.dispatch('projectStepInteraction/setItem', {})
                        this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                        this.$store.dispatch('task/setItem', {});
                        this.$emit('modified')
                    })
                } else {
                    this.taskModal = false;
                    this.$store.dispatch('projectStepInteraction/setItem', {})
                    this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                    this.$store.dispatch('task/setItem', {});
                    this.$emit('modified')
                }
            })
        },


        saveInvoicing: function (newInvoicing) {
            const interaction = this.step?.interactions.find(int => int.translation_key === 'interactions::interactions.interactions.invoicing')

            const data = {
                project_step_id: this.model?.id,
                interaction_id: interaction?.id,
                invoice: Object.assign({}, newInvoicing, {
                    target_type: this.project?.target_type,
                    target_id: this.project?.target_id,
                    reference_type: 'Appon\\Projects\\Models\\Project',
                    reference_id: this.project?.id
                })
            }

            return this.$store.dispatch(`projectStepInteraction/create`, data).then(() => {
                this.invoicingModal = false
                this.$store.dispatch('projectStepInteraction/setItem', {})
                this.$store.dispatch('projectStepInteraction/increase', interaction?.id)
                this.$store.dispatch('invoice/setItem', {});
                this.$emit('modified')
            })
        },

        increaseCount: function (id) {
            this.$store.dispatch('projectStepInteraction/increase', id)
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        },

        listInteraction: function (interaction) {
            this.interaction = interaction
            this.projectStepInteractionList = true
        },

        refresh: function () {
            this.$emit('modified');
        },
    },

    watch: {
        projectStep: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value)
                if (value?.id) {
                    this.$store.dispatch('projectStepInteraction/counted', { project_step_id: value?.id })
                } else {
                    this.$store.dispatch('projectStepInteraction/clearCounted')
                }
            },
        },

        step: {
            immediate: true,
            deep: true,
            handler: function (step) {
                if (step?.id) {
                    this.$store.dispatch('step/statuses', {relatedTo: { relation: 'steps', id: step?.id }})
                }
            },
        }
    },

    unmounted() {
        this.$store.dispatch('projectStepInteraction/clearCounted')
    },

    created() {
        this.setModel(this.projectStep)
    }
})
</script>

<template>
    <form @submit.prevent>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row justify-content-start">
                <div class="col flex-grow-0 mb-4">
                    <label
                        class="form-label"
                        for="interactions"
                    >{{ $tc('interactions::interactions.interaction', 2).ucFirst() }}</label>
                    <div>
                        <div class="btn-group btn-group" :key="step?.interactions?.length">
                            <b-dropdown variant="outline-light" :toggle-class="{'bg-soft-danger': requiredInteractions.includes(interaction.id)}" v-for="(interaction, index) in step?.interactions" :key="index">
                                <template #button-content>
                                <span class="noti-icon" :title="$t(interaction.translation_key || interaction.name).ucFirst()">
                                    <i :class="interaction.icon + ' ' + (requiredInteractions.includes(interaction.id) ? 'text-danger' : 'text-primary')"></i>
                                    <span v-if="counted?.[interaction.id]" class="noti-dot bg-warning rounded-pill">{{ counted?.[interaction.id] }}</span>
                                </span>
                                </template>
                                <template #default>
                                    <b-dropdown-item-button v-if="!disabled" variant="primary" @click="createInteraction(interaction)">
                                        <i class="fas fa-plus me-2"></i>
                                        <span>
                                            {{ $t('base.add_new').ucFirst() }}
                                        </span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="listInteraction(interaction)">
                                        <i class="fas fa-list-alt me-2"></i>
                                        <span>
                                        {{ $t('base.list').ucFirst() }}
                                    </span>
                                    </b-dropdown-item-button>
                                </template>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4" v-if="!fix || !model.project_id">
                    <label
                        :class="{'text-danger': states.project_id === false}"
                        class="form-label"
                        for="project_id"
                    >{{ $t('projects::project_steps.columns.project_id').ucFirst() }}</label>
                    <select
                        id="project_id"
                        v-model="model.project_id"
                        :class="{'border-danger': states.project_id === false}"
                        class="form-select"
                        name="project_id"
                        :disabled="disabled"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_steps.placeholders.project_id').ucFirst() }}
                        </option>
                        <option v-for="(project, key) in projects" :key="key" :value="project.id">{{
                                project.translation_key ? $t(project.translation_key).ucFirst() : project.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.project_id === false}" class="invalid-feedback">
                        {{ errors.project_id }}
                    </div>
                </div>
                <div class="col-lg-4 mb-4" v-if="!fix || !model.step_id">
                    <label
                        :class="{'text-danger': states.step_id === false}"
                        class="form-label"
                        for="step_id"
                    >{{ $t('projects::project_steps.columns.step_id').ucFirst() }}</label>
                    <select
                        id="step_id"
                        v-model="model.step_id"
                        :class="{'border-danger': states.step_id === false}"
                        class="form-select"
                        name="step_id"
                        :disabled="disabled"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_steps.placeholders.step_id').ucFirst() }}
                        </option>
                        <option v-for="(step, key) in steps" :key="key" :value="step.id">{{
                                step.translation_key ? $t(step.translation_key).ucFirst() : step.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.step_id === false}" class="invalid-feedback">
                        {{ errors.step_id }}
                    </div>
                </div>
                <div class="col-12 col-sm mb-4">
                    <label
                        :class="{'text-danger': states.status_id === false}"
                        class="form-label"
                    >{{ $t('projects::project_steps.columns.status_id').ucFirst() }}</label>
                    <select
                        v-if="false"
                        id="status_id"
                        v-model="model.status_id"
                        :class="{'border-danger': states.status_id === false}"
                        class="form-select"
                        name="status_id"
                        :disabled="disabled"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_steps.placeholders.status_id').ucFirst() }}
                        </option>
                        <option v-for="(status, key) in statuses" :key="key" :value="status.id" :class="{'bg-soft-warning': status.id === requiredStatus}">{{
                                status.translation_key ? $t(status.translation_key).ucFirst() : status.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.status_id === false}" class="invalid-feedback">
                        {{ errors.status_id }}
                    </div>
                    <status-selector v-model="model.status_id" :statuses="statuses" :disabled="disabled"></status-selector>
                </div>
                <div class="col-lg-3 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.realized_at === false}"
                        class="form-label"
                        for="realized_at"
                    >{{ $t('projects::project_steps.columns.realized_at').ucFirst() }}</label
                    >
                    <input
                        id="realized_at"
                        v-model="model.realized_at"
                        :class="{'border-danger': states.realized_at === false}"
                        :placeholder="$t('projects::project_steps.placeholders.realized_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                        :disabled="disabled"
                    />
                    <div :class="{'d-block': states.realized_at === false}" class="invalid-feedback">
                        {{ errors.realized_at }}
                    </div>
                </div>
                <div class="col-lg-1 mb-4" v-if="false">
                    <label
                        class="form-label"
                        for="failed"
                    >{{ $t('projects::project_steps.columns.failed').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="failed" v-model="model.failed" :disabled="disabled"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                    <div :class="{'d-block': states.failed === false}" class="invalid-feedback">
                        {{ errors.failed }}
                    </div>
                </div>
                <div class="col-lg-1 mb-4" v-if="false">
                    <label
                        class="form-label"
                        for="successful"
                    >{{ $t('projects::project_steps.columns.successful').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="successful" v-model="model.successful" :disabled="disabled"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                    <div :class="{'d-block': states.successful === false}" class="invalid-feedback">
                        {{ errors.successful }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.notification === false}"
                        class="form-label"
                        for="notification"
                    >{{ $t('projects::project_steps.columns.notification').ucFirst() }}</label
                    >

                    <textarea
                        id="notification"
                        v-model="model.notification"
                        :class="{'border-danger': states.notification === false}"
                        :placeholder="$t('projects::project_steps.placeholders.notification').ucFirst()"
                        class="form-control"
                        rows="5"
                        :disabled="disabled"
                    />
                    <div :class="{'d-block': states.notification === false}" class="invalid-feedback">{{ errors.notification }}</div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!disabled" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>

            <b-modal size="xl" v-model="offerModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('interactions::meetings.meeting', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <offer-form :showed="offerModal" :offer="offer" :redirect="!fix" :fixed="true" :doNotSave="true" @updated="(newOffer) => saveOffer(newOffer)">
                    <template #extra-fields>
                        <div class="row mb-4">
                            <div class="col-lg-12">
                                <label
                                    :class="{'text-danger': states.notification === false}"
                                    class="form-label"
                                    for="notification"
                                >{{ $t('projects::project_step_interactions.columns.notification').ucFirst() }}</label>
                                <textarea
                                    id="notification"
                                    v-model="notification"
                                    :class="{'border-danger': states.notification === false}"
                                    :placeholder="$t('projects::project_step_interactions.placeholders.notification').ucFirst()"
                                    class="form-control"
                                    rows="3"
                                ></textarea>
                                <div :class="{'d-block': states.notification === false}" class="invalid-feedback">
                                    {{ errors.notification }}
                                </div>
                            </div>
                        </div>
                    </template>
                </offer-form>
            </b-modal>
            <b-modal size="xl" v-model="implementModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('projects::implements.implement', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <implement-form :implement="implement" :redirect="!fix" :doNotSave="true" @updated="(newImplementing) => saveImplementing(newImplementing)"></implement-form>
            </b-modal>
            <b-modal size="xl" v-model="invoicingModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('invoices::invoices.invoice', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <invoice-form :showed="invoicingModal" :invoice="invoice" :project-id="project?.id" :fixed="true" :redirect="!fix" :doNotSave="true" @updated="(newInvoicing) => saveInvoicing(newInvoicing)"></invoice-form>
            </b-modal>
            <b-modal size="xl" v-model="contractModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('projects::contracts.contract', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <contract-form :clear="contractModal" :contract="contract" :redirect="!fix" :fixed="true" :doNotSave="true" @updated="(newContract) => saveContract(newContract)"></contract-form>
            </b-modal>
            <b-modal size="xl" v-model="meetingModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('interactions::meetings.meeting', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <meeting-form :meeting="meeting" :redirect="!fix" :fixed="true" :own="true" :doNotSave="true" @updated="(newMeeting) => saveMeeting(newMeeting)"></meeting-form>
            </b-modal>
            <b-modal size="xl" v-model="reminderModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('reminders::reminders.reminder', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <reminder-form :reminder="reminder" :redirect="!fix" :doNotSave="true" @updated="(newReminder) => saveReminder(newReminder)"></reminder-form>
            </b-modal>
            <b-modal size="xl" v-model="taskModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.add_new_item', {item: $tc('tasks::tasks.task', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <task-form :task="task" :own="true" :accessible="false" :redirect="!fix" :fix-client="true" :fix-assigned="true" :fix-inducers="true" :doNotSave="true" @updated="(newTask) => saveTask(newTask)"></task-form>
            </b-modal>
            <b-modal size="xl" v-model="projectStepInteractionModal" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.edit_item', {item: $tc('projects::project_step_interactions.project_step_interaction', 1)}).ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                            {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                        </span>
                </template>
                <project-step-interaction-form v-if="projectStepInteractionModal" :key="projectStepInteractionModal"
                                               :only-successful="true"
                                               :redirect="false"
                                               :project-step-interaction="projectStepInteraction"
                                               :project-step="projectStep"
                                               :interaction="interaction"
                                               @saved="projectStepInteractionModal = false; increaseCount(interaction.id); $emit('modified')"
                >
                </project-step-interaction-form>
            </b-modal>
            <b-modal size="xl" v-model="projectStepInteractionList" body-bg-variant="light" body-class="rounded" hide-footer>
                <template #title>
                    <span>{{ $t('base.list').ucFirst() }}</span>
                    <i v-if="interaction.icon" class="mx-3 text-primary" :class="interaction.icon"></i>
                    <span class="text-primary">
                        {{ interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name }}
                    </span>
                </template>
                <project-step-interaction-list v-if="projectStepInteractionList" :project="project" :project-step="projectStep" :interaction="interaction" @close="projectStepInteractionList = false" @refresh="refresh"></project-step-interaction-list>
            </b-modal>
        </b-overlay>
    </form>
</template>

<style scoped>
    .noti-icon {
        .noti-dot {
            top: unset;
            right: unset;
            bottom: -8px;
            left: 12px;
            opacity: 0.3;
            transition: 0.3s;
        }
    }

    :deep(.btn-group .btn:hover) {
        .noti-icon {
            .noti-dot {
                opacity: 1;
            }
        }
    }
</style>
