<script>
export default {
    name: "object-property-list",

    emits: ['close'],

    props: {
        item: {
            type: Object,
            required: true
        },

        properties: {
            type: Array
        },

        translationKey: {
            type: String
        },

        open: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        keys: function () {
            return this.properties || Object.keys(this.item)
        },
    },

    data: function () {
        return {
            show: false
        }
    },

    methods: {
        getFromStore: function (prop, value) {
            if (!prop) {
                return;
            }

            const store = prop.replace(
                /(?!^)_(.)/g,
                (_, char) => char.toUpperCase()
            );

            if (! this.$store.hasModule(store)) {
                return;
            }

            const item = this.$store.getters[store + '/all']?.find(i => i.id === value);

            return item?.translation_key ? this.$t(item.translation_key).ucFirst() : item?.name;
        },

        close: function () {
            this.$emit('close');
        },
    },

    watch: {
        open: {
            immediate: true,
            handler: function (value) {
                this.show = value;
            },
        }
    }
}
</script>

<template>
    <div class="row" :class="$attrs.class" v-for="(property, index) in keys" :key="index">
        <div class="col-6 font-size-13">{{ property.translation }}</div>
        <div class="col-6">
            <strong>
                <template v-if="!property.versioned">
                    <template v-if="!property.selected">
                        <template v-if="property.options">
                            {{ property.options[item[property?.key]] ? $t(property.options[item[property?.key]]).ucFirst() : item[property?.key] }}
                        </template>
                        <template v-else>
                            {{ item[property?.key] }}
                        </template>
                    </template>
                    <template v-else>
                        {{ item[property?.key] ? (item[property?.key]?.translation_key ? $t(item[property?.key]?.translation_key).ucFirst() : (item[property?.key]?.name)) : getFromStore(property?.key, item[property?.key + '_id']) }}
                    </template>
                </template>
                <template v-else >
                    <template v-if="property?.options">
                        <span>{{ property.options[item[property?.key]?.[0]?.item?.value] ? $t(property.options[item[property?.key]?.[0]?.item?.value]).ucFirst() : item[property?.key]?.[0]?.item?.value }}</span>
                        <span v-if="property.unit" class="ms-2">{{ property.unit }}</span>
                    </template>
                    <template v-else>
                        <span>{{ item[property?.key]?.[0]?.item?.value }}</span>
                        <span v-if="property.unit" class="ms-2">{{ property.unit }}</span>
                    </template>
                </template>
            </strong>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <b-button variant="primary" @click="show = !show">
                <span class="me-2">
                    <i class="fas fa-pencil-alt"></i>
                </span>
                <span>{{ $t('base.edit').ucFirst() }}</span>
            </b-button>
        </div>
    </div>
    <b-modal size="xl" v-model="show" body-bg-variant="light" body-class="rounded" :title="item.name" hide-footer @hidden="close">
        <slot name="modal" :close="() => show = !show"></slot>
    </b-modal>
</template>

<style scoped>

</style>
