<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import TranslationInput from "../elements/translation-input.vue";
import FileInput from "../elements/file-input.vue";
import MultipleSelect from "../elements/multiple-select.vue";
import BillablesManager from "../elements/billables-manager.vue";

export default defineComponent({
    name: "contract-form",

    emits: ['saved', 'updated'],

    props: {
        contract: {
            type: Object,
            required: true
        },

        fixed: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        showed: {
            type: Boolean,
            default: true
        }
    },

    components: {BillablesManager, MultipleSelect, FileInput, TranslationInput},

    computed: {
        ...mapGetters({
            'loading': 'contract/loading',
            'states': 'contract/states',
            'errors': 'contract/errors',
            fields: 'field/list',
            projects: 'project/own',
            contractTypes: 'contractType/all',
            fieldVersions: 'fieldVersion/all',
            project: 'project/item',
            locale: 'trans/locale',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        externalFileStore: function () {
            return this.$store.getters['app/configuration']?.projects.required_file_type === 'link';
        },

        localFileStore: function () {
            return this.$store.getters['app/configuration']?.projects.required_file_type === 'file';
        },

        readOnlyAmount: function () {
            return this.model?.billables?.length;
        },
    },

    data: function () {
        return {
            model: {},
            dynamicSkeleton: {}
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            if (!this.doNotSave) {
                return this.$store.dispatch(`contract/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create') {
                        this.$router.push(this.$t('routes.'+'/interactions/my-contracts/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('contract/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        sent: function () {
            return this.$store.dispatch(`contract/sent`, this.model.id).then(saved => {
                this.setModel(saved);
                this.$emit('saved');
            })
        },

        rejected: function () {
            return this.$store.dispatch(`contract/rejected`, this.model.id).then(saved => {
                this.setModel(saved);
                this.$emit('saved');
            })
        },

        canceled: function () {
            return this.$store.dispatch(`contract/canceled`, this.model.id).then(saved => {
                this.setModel(saved);
                this.$emit('saved');
            })
        },

        signed: function () {
            return this.$store.dispatch(`contract/signed`, this.model.id).then(saved => {
                this.setModel(saved);
                this.$emit('saved');
            })
        },

        closed: function () {
            return this.$store.dispatch(`contract/closed`, this.model.id).then(saved => {
                this.setModel(saved);
                this.$emit('saved');
            })
        },

        fetchContractTypes: function () {
            this.$store.dispatch('contractType/all')
        },

        setModel: function (model = {}) {
            const base = {}

            if (!model.id) {
                base.sent_at = (new Date()).formattedDate()
            }

            this.model = Object.assign(base, this.dynamicSkeleton, JSON.parse(JSON.stringify(model)))
        },

        setFile: function (event, prop = 'file_file') {
            this.model[prop] = event.target.files[0]
        },

        handleBillables: function (offers, removed) {
            const list = removed ? removed : offers;
            const billables = list?.map(o => o.billables).flat() || [];
            billables?.forEach(billable => {
                const index = this.model.billables.findIndex(ex => ex.billable_type === billable.billable_type && ex.billable_id === billable.billable_id && ex.quantity === billable.quantity);
                if (removed) {
                    if (index !== -1) {
                        this.model.billables.splice(index, 1);
                    }
                } else {
                    if (index === -1) {
                        this.model.billables.push(billable);
                    }
                }
            });
        },
    },

    watch: {
        contract: function (value) {
            this.setModel(value)
        },

        fields: function (fields) {
            const skeleton = {}

            fields.forEach(field => {
                if (field.versionable) {
                    skeleton[field.transformed_name] = [{
                        version: {
                            name: null,
                            date: null
                        },
                        item: {}
                    }];
                }
            })

            this.dynamicSkeleton = skeleton

            this.setModel(this.model)
        },
    },

    created() {
        this.setModel(this.contract)
        this.fetchContractTypes()
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div v-if="model.project_id" class="col-12 mb-4 mb-lg-5">
                    <h3 id="contract-project" class="text-muted border-bottom pb-2 border-secondary">
                        <router-link :to="$t('routes./projects/accessible-projects/:id(\\d+)', {id: model.project_id}).rmValidation()">
                            <i class="fas fa-clipboard-list me-3"></i>
                            <span>{{ model?.project?.name }}</span>
                        </router-link>
                    </h3>
                    <p v-if="model.sent_at || model.rejected_at || model.canceled_at || model.signed_at || model.closed_at">
                        <template v-if="model.closed_at">
                            <i class="fas fa-lock text-success me-2" :title="model.closed_at.printDate(locale)"></i>
                            <small>{{ model.closed_at.printDate(locale) }}</small>
                        </template>
                        <template v-else-if="model.signed_at">
                            <i class="fas fa-signature text-success me-2" :title="model.signed_at.printDate(locale)"></i>
                            <small>{{ model.signed_at.printDate(locale) }}</small>
                        </template>
                        <template v-else-if="model.canceled_at">
                            <i class="fas fa-times text-danger me-2" :title="model.canceled_at.printDate(locale)"></i>
                            <small>{{ model.canceled_at.printDate(locale) }}</small>
                        </template>
                        <template v-else-if="model.rejected_at">
                            <i class="fas fa-ban text-danger me-2" :title="model.rejected_at.printDate(locale)"></i>
                            <small>{{ model.rejected_at.printDate(locale) }}</small>
                        </template>
                        <template v-else-if="model.sent_at">
                            <i class="fas fa-ban text-primary me-2" :title="model.sent_at.printDate(locale)"></i>
                            <small>{{ model.sent_at.printDate(locale) }}</small>
                        </template>
                    </p>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.code === false}"
                                class="form-label"
                                for="code"
                            >{{ $t('projects::contracts.columns.code').ucFirst() }} *</label
                            >

                            <input
                                id="code"
                                v-model="model.code"
                                :class="{'border-danger': states.code === false}"
                                :placeholder="$t('projects::contracts.placeholders.code').ucFirst()"
                                class="form-control"
                                type="text"
                            />
                            <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.title === false}"
                                class="form-label"
                                for="title"
                            >{{ $t('projects::contracts.columns.title').ucFirst() }} *</label
                            >

                            <input
                                id="title"
                                v-model="model.title"
                                :class="{'border-danger': states.title === false}"
                                :placeholder="$t('projects::contracts.placeholders.title').ucFirst()"
                                class="form-control"
                                type="text"
                            />
                            <div :class="{'d-block': states.title === false}" class="invalid-feedback">{{ errors.title }}</div>
                        </div>
                        <div class="col-lg-3 mb-4" v-if="false">
                            <label
                                :class="{'text-danger': states.translation_key === false}"
                                class="form-label"
                                for="translation_key"
                            >{{ $t('projects::contracts.columns.translation_key').ucFirst() }}</label
                            >
                            <translation-input
                                v-model="model.translation_key"
                                :error="errors.translation_key"
                                :placeholder="$t('projects::contracts.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                                :state="states.translation_key"
                            ></translation-input>
                            <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                                {{ errors.translation_key }}
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.contract_type_id === false}"
                                class="form-label"
                                for="contract_type_id"
                            >{{ $t('projects::contracts.columns.contract_type_id').ucFirst() }}</label>
                            <select
                                id="contract_type_id"
                                v-model="model.contract_type_id"
                                :class="{'border-danger': states.contract_type_id === false}"
                                class="form-select"
                                name="contract_type_id"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::contracts.placeholders.contract_type_id').ucFirst() }}
                                </option>
                                <option v-for="(contractType, key) in contractTypes" :key="key" :value="contractType.id">{{
                                        contractType.translation_key ? $t(contractType.translation_key).ucFirst() : contractType.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.contract_type_id === false}" class="invalid-feedback">
                                {{ errors.contract_type_id }}
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.amount === false}"
                                class="form-label"
                                for="amount"
                            >{{ $t('projects::contracts.columns.amount').ucFirst() }} *</label
                            >
                            <div class="input-group">
                                <div class="input-group-text">
                                    <i class="fas fa-dollar-sign"></i>
                                </div>
                                <input
                                    id="amount"
                                    v-model="model.amount"
                                    :class="{'border-danger': states.amount === false, 'bg-light': readOnlyAmount}"
                                    :placeholder="$t('projects::contracts.placeholders.amount').ucFirst()"
                                    class="form-control"
                                    type="number"
                                    step="any"
                                    :readonly="readOnlyAmount"
                                />
                            </div>
                            <div :class="{'d-block': states.amount === false}" class="invalid-feedback">
                                {{ errors.amount }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4" v-if="!fixed && !model.project_id">
                            <label
                                :class="{'text-danger': states.project_id === false}"
                                class="form-label"
                                for="project_id"
                            >{{ $t('projects::contracts.columns.project_id').ucFirst() }}</label>
                            <select
                                id="project_id"
                                v-model="model.project_id"
                                :class="{'border-danger': states.project_id === false}"
                                class="form-select"
                                name="project_id"
                            >
                                <option :value="model.project_id ? null : undefined">
                                    {{ $t('projects::contracts.placeholders.project_id').ucFirst() }}
                                </option>
                                <option v-for="(project, key) in projects" :key="key" :value="project.id">{{
                                        project.translation_key ? $t(project.translation_key).ucFirst() : project.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.project_id === false}" class="invalid-feedback">
                                {{ errors.project_id }}
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.deadline === false}"
                                class="form-label"
                                for="deadline"
                            >{{ $t('projects::contracts.columns.deadline').ucFirst() }} *</label
                            >
                            <input
                                id="deadline"
                                v-model="model.deadline"
                                :class="{'border-danger': states.deadline === false}"
                                :placeholder="$t('projects::contracts.placeholders.deadline').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.deadline === false}" class="invalid-feedback">
                                {{ errors.deadline }}
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.start === false}"
                                class="form-label"
                                for="start"
                            >{{ $t('projects::contracts.columns.start').ucFirst() }}</label
                            >
                            <input
                                id="start"
                                v-model="model.start"
                                :class="{'border-danger': states.start === false}"
                                :placeholder="$t('projects::contracts.placeholders.start').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.start === false}" class="invalid-feedback">
                                {{ errors.start }}
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.end === false}"
                                class="form-label"
                                for="end"
                            >{{ $t('projects::contracts.columns.end').ucFirst() }}</label
                            >
                            <input
                                id="end"
                                v-model="model.end"
                                :class="{'border-danger': states.end === false}"
                                :placeholder="$t('projects::contracts.placeholders.end').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.end === false}" class="invalid-feedback">
                                {{ errors.end }}
                            </div>
                        </div>

                        <div class="col-lg-3 mb-4">
                            <label
                                :class="{'text-danger': states.offers === false}"
                                class="form-label"
                                for="offers"
                            >{{ $t('projects::contracts.relationships.offers').ucFirst() }}</label
                            >
                            <multiple-select v-if="model.project_id || project.id" id="offers" v-model="model.offers" store="offer" :query="{project_id: model.project_id || project.id, accepted: true, with: ['billables']}" @change="handleBillables"></multiple-select>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row mb-4 pt-4 px-3">
                        <div class="col-12 bg-soft-primary rounded pt-3">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-primary">
                                        <i class="fas fa-paperclip"></i>
                                    </h3>
                                </div>
                                <div v-if="localFileStore" class="col-12 mb-4 ps-lg-5">
                                    <label
                                        :class="{'text-danger': states.file === false}"
                                        class="form-label"
                                        for="file"
                                    >{{ $t('projects::contracts.columns.file_id').ucFirst() }}</label>
                                    <file-input
                                        id="file_file"
                                        name="file_file"
                                        :class="{'border-danger': states.file === false}"
                                        :placeholder="$t('interactions::interactions.interactions.contract').ucFirst()"
                                        @change="setFile($event)"
                                    ></file-input>
                                    <div :class="{'d-block': states.file === false}" class="invalid-feedback">
                                        {{ errors.file }}
                                    </div>
                                </div>
                                <div v-if="externalFileStore" class="col-12 mb-4 ps-lg-5">
                                    <label
                                        :class="{'text-danger': states.file_link === false}"
                                        class="form-label"
                                        for="file_link"
                                    >{{ $t('projects::contracts.columns.file_link').ucFirst() }}</label
                                    >
                                    <div class="input-group">
                                        <div class="input-group-text">
                                            <i class="fa fa-link"></i>
                                        </div>
                                        <input
                                            id="file_link"
                                            v-model="model.file_link"
                                            :class="{'border-danger': states.file_link === false}"
                                            :placeholder="$t('projects::contracts.placeholders.file_link').ucFirst()"
                                            class="form-control"
                                            type="url"
                                        />
                                    </div>
                                    <div :class="{'d-block': states.file_link === false}" class="invalid-feedback">{{ errors.file_link }}</div>
                                </div>
                                <div v-if="localFileStore" class="col-12 mb-4 ps-lg-5">
                                    <label
                                        :class="{'text-danger': states.signed_file === false}"
                                        class="form-label"
                                        for="signed_file"
                                    >{{ $t('projects::contracts.columns.signed_file_id').ucFirst() }}</label>
                                    <file-input
                                        id="signed_file_file"
                                        name="signed_file_file"
                                        :class="{'border-danger': states.signed_file === false}"
                                        :placeholder="$t('interactions::interactions.interactions.contract').ucFirst()"
                                        @change="setFile($event, 'signed_file_file')"
                                    ></file-input>
                                    <div :class="{'d-block': states.signed_file === false}" class="invalid-feedback">
                                        {{ errors.signed_file }}
                                    </div>
                                </div>
                                <div v-if="externalFileStore" class="col-12 mb-4 ps-lg-5">
                                    <label
                                        :class="{'text-danger': states.signed_file_link === false}"
                                        class="form-label"
                                        for="signed_file_link"
                                    >{{ $t('projects::contracts.columns.signed_file_link').ucFirst() }}</label
                                    >
                                    <div class="input-group">
                                        <div class="input-group-text">
                                            <i class="fa fa-link"></i>
                                        </div>
                                        <input
                                            id="signed_file_link"
                                            v-model="model.signed_file_link"
                                            :class="{'border-danger': states.signed_file_link === false}"
                                            :placeholder="$t('projects::contracts.placeholders.signed_file_link').ucFirst()"
                                            class="form-control"
                                            type="url"
                                        />
                                    </div>
                                    <div :class="{'d-block': states.signed_file_link === false}" class="invalid-feedback">{{ errors.signed_file_link }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showed" class="row mb-4" :key="model?.billables?.length">
                <div class="col-12">
                    <billables-manager v-model="model.billables" @update:amount="(amount) => model.amount = amount"></billables-manager>
                </div>
            </div>
            <div v-if="false" class="row mb-4">
                <div v-if="false" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.sent_at === false}"
                        class="form-label"
                        for="sent_at"
                    >{{ $t('projects::contracts.columns.sent_at').ucFirst() }}</label
                    >
                    <input
                        id="sent_at"
                        v-model="model.sent_at"
                        :class="{'border-danger': states.sent_at === false}"
                        :placeholder="$t('projects::contracts.placeholders.sent_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.sent_at === false}" class="invalid-feedback">
                        {{ errors.sent_at }}
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.canceled_at === false}"
                        class="form-label"
                        for="canceled_at"
                    >{{ $t('projects::contracts.columns.canceled_at').ucFirst() }}</label
                    >
                    <input
                        id="canceled_at"
                        v-model="model.canceled_at"
                        :class="{'border-danger': states.canceled_at === false}"
                        :placeholder="$t('projects::contracts.placeholders.canceled_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.canceled_at === false}" class="invalid-feedback">
                        {{ errors.canceled_at }}
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.signed_at === false}"
                        class="form-label"
                        for="signed_at"
                    >{{ $t('projects::contracts.columns.signed_at').ucFirst() }}</label
                    >
                    <input
                        id="signed_at"
                        v-model="model.signed_at"
                        :class="{'border-danger': states.signed_at === false}"
                        :placeholder="$t('projects::contracts.placeholders.signed_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.signed_at === false}" class="invalid-feedback">
                        {{ errors.signed_at }}
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.rejected_at === false}"
                        class="form-label"
                        for="rejected_at"
                    >{{ $t('projects::contracts.columns.rejected_at').ucFirst() }}</label
                    >
                    <input
                        id="rejected_at"
                        v-model="model.rejected_at"
                        :class="{'border-danger': states.rejected_at === false}"
                        :placeholder="$t('projects::contracts.placeholders.rejected_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.rejected_at === false}" class="invalid-feedback">
                        {{ errors.rejected_at }}
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.closed_at === false}"
                        class="form-label"
                        for="closed_at"
                    >{{ $t('projects::contracts.columns.closed_at').ucFirst() }}</label
                    >
                    <input
                        id="closed_at"
                        v-model="model.closed_at"
                        :class="{'border-danger': states.closed_at === false}"
                        :placeholder="$t('projects::contracts.placeholders.closed_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.closed_at === false}" class="invalid-feedback">
                        {{ errors.closed_at }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button class="btn btn-primary mb-2 mb-md-0" :title="btnText" type="submit" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span class="ms-2 d-none d-md-inline-block">{{ btnText }}</span>
                    </button>
                    <slot name="buttons" btnClass="mb-2 mb-md-0" :save="save"></slot>
                    <template v-if="model.id">
                        <button v-if="model.sent_at === null" :title="$t('base.sent').ucFirst()" class="btn btn-success mb-2 mb-md-0 ms-2" type="submit" @click.prevent="sent">
                            <i class="fas fa-mail-bulk"></i>
                            <span class="ms-2 d-none d-md-inline-block">{{ $t('base.sent').ucFirst() }}</span>
                        </button>
                        <button v-if="model.signed_at === null && model.sent_at !== null" :title="$t('base.signed').ucFirst()" class="btn btn-success mb-2 mb-md-0 ms-2" type="submit" @click.prevent="signed">
                            <i class="fas fa-signature"></i>
                            <span class="ms-2 d-none d-md-inline-block">{{ $t('base.signed').ucFirst() }}</span>
                        </button>
                        <button v-if="model.closed_at === null && model.signed !== null" :title="$t('base.closed').ucFirst()" class="btn btn-success mb-2 mb-md-0 ms-2" type="submit" @click.prevent="closed">
                            <i class="fas fa-lock"></i>
                            <span class="ms-2 d-none d-md-inline-block">{{ $t('base.closed').ucFirst() }}</span>
                        </button>
                        <button v-if="model.rejected_at === null && model.signed === null" :title="$t('base.rejected').ucFirst()" class="btn btn-warning mb-2 mb-md-0 ms-2" type="submit" @click.prevent="rejected">
                            <i class="fas fa-ban"></i>
                            <span class="ms-2 d-none d-md-inline-block">{{ $t('base.rejected').ucFirst() }}</span>
                        </button>
                        <button v-if="model.canceled_at === null && model.signed !== null" :title="$t('base.canceled').ucFirst()" class="btn btn-danger mb-2 mb-md-0 ms-2" type="submit" @click.prevent="canceled">
                            <i class="fas fa-times"></i>
                            <span class="ms-2 d-none d-md-inline-block">{{ $t('base.canceled').ucFirst() }}</span>
                        </button>
                    </template>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>
    #contract-project i {
        transform: scale(0.9) rotate(-10deg);
        transition: 0.3s;
    }

    #contract-project:hover i {
        transform: scale(1);
        transition: 0.3s;
    }
</style>
