<script>
import {defineComponent} from 'vue'
import TranslationInput from "../../elements/translation-input.vue";
import {mapGetters} from "vuex";
import MultipleSelect from "../../elements/multiple-select.vue";
import MultipleFile from "../../elements/multiple-file.vue";
import ReminderForm from "../reminder-form.vue";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Adapter from '@/api/upload.js';
import '@/translations/ckeditor.js';

export default defineComponent({
    name: "task-form",

    emits: ['saved', 'updated'],

    props: {
        task: {
            type: Object,
            required: true
        },

        own: {
            type: Boolean,
            required: true
        },

        accessible: {
            type: Boolean,
            required: true
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        fixAssigned: {
            type: Boolean,
            default: false
        },

        fixClient: {
            type: Boolean,
            default: false
        },

        fixInducers: {
            type: Boolean,
            default: false
        }
    },

    components: {ReminderForm, MultipleFile, MultipleSelect, TranslationInput,
        ckeditor: CKEditor.component},

    computed: {
        ...mapGetters({
            'loading': 'task/loading',
            'states': 'task/states',
            'errors': 'task/errors',
            reminder: 'reminder/item',
            types: 'taskType/all',
            priorities: 'taskPriority/all',
            statuses: 'taskStatus/all',
            tasks: 'task/all',
            templates: 'taskTemplate/all',
            locale: 'trans/locale',
            authenticated: 'user/authenticated'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        userModelTypes: function () {
            return [
                {
                    value: 'App\\Models\\User',
                    store: 'user',
                    label: this.$tc('users.user', 2)
                }
            ]
        },

        clientModelTypes: function () {
            return [
                {
                    value: 'Appon\\Partners\\Models\\Partner',
                    store: 'partner',
                    label: this.$tc('partners::partners.partner', 2)
                }
            ]
        },

        editorConfig:  function () {
            return {
                extraPlugins: [this.uploader],
                language: this.locale,
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                    ]
                }
            };
        },
    },

    data: function () {
        return {
            model: {},
            users: [],
            assigned: [],
            clients: [],
            createReminder: false,
            selectedTemplate: null,
            editor: ClassicEditor,
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            if (!this.doNotSave) {
                return this.$store.dispatch(`task/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create' && this.redirect) {
                        const translationKey = this.accessible ? 'routes./tasks/accessible-tasks/:id(\\d+)' : (this.own ? 'routes./tasks/my-tasks/:id(\\d+)' : 'routes.'+'/master-data/tasks/tasks/:id(\\d+)')
                        this.$router.push(this.$t(translationKey, {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('task/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        setModel: function (model = {}) {
            const base = {
                client_type: 'Appon\\Partners\\Models\\Partner',
                user_type: 'App\\Models\\User',
                user_id: this.own ? this.authenticated.id : null,
                assigned_type: 'App\\Models\\User',
                assigned_id: this.own ? this.authenticated.id : null,
                description: ''
            };

            const obj = Object.assign({}, base, JSON.parse(JSON.stringify(model)));

            if (!obj.description) {
                obj.description = '';
            }

            this.model = obj;
        },

        fetchUsers: function () {
            const store = this.userModelTypes.find(model => model.value === this.model.user_type)?.store

            if (store) {
                // const method = this.own ? null : (this.accessible ? 'subordinates' : 'all');
                const method = 'all';
                if (method) {
                    this.$store.dispatch(`${store}/${method}`, true).then(response => {
                        this.users = response;
                    });
                }
            } else {
                this.users = []
            }
        },

        fetchAssigned: function () {
            const store = this.userModelTypes.find(model => model.value === this.model.assigned_type)?.store

            if (store) {
                // const method = this.own ? null : (this.accessible ? 'subordinates' : 'all');
                const method = 'all';
                if (method) {
                    this.$store.dispatch(`${store}/${method}`, true).then(response => {
                        this.assigned = response;
                    });
                }
            } else {
                this.assigned = []
            }
        },

        fetchClients: function () {
            const store = this.clientModelTypes.find(model => model.value === this.model.client_type)?.store

            if (store) {
                const method = this.own ? 'own' : (this.accessible ? 'accessible' : 'all');
                this.$store.dispatch(`${store}/${method}`, {}).then(response => {
                    this.clients = response
                });
            } else {
                this.clients = []
            }
        },

        uploader(editor)
        {
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new Adapter( loader );
            };
        },
    },

    watch: {
        task: {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (value) {
                    this.setModel(value);
                }
            },
        },

        'model.client_type': {
            deep: true,
            immediate: true,
            handler: function () {
                this.fetchClients()
            },
        },

        'model.assigned_type': {
            deep: true,
            immediate: true,
            handler: function () {
                if (!this.own) {
                    this.fetchAssigned();
                }
            },
        },

        'model.user_type': {
            deep: true,
            immediate: true,
            handler: function () {
                this.fetchUsers()
            },
        },

        selectedTemplate: function (value) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('tasks::templates.confirm_template.title'),
                text: this.$t('tasks::templates.confirm_template.message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.override').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    const template = {
                        content: value.content,
                        content_translation_key: value.content_translation_key,
                        description: value.description,
                        description_translation_key: value.description_translation_key,
                        name: value.name,
                        priority_id: value.priority_id,
                        status_id: value.status_id,
                        translation_key: value.translation_key,
                        type_id: value.type_id
                    }
                    this.model = Object.assign({}, this.model, template)
                }
            });
        },
    },

    created() {
        this.$store.dispatch('taskTemplate/all', true)
        this.$store.dispatch('taskPriority/all', true)
        this.$store.dispatch('taskStatus/all', true)
        this.$store.dispatch('taskType/all', true)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div v-if="!model.id" class="col-lg-3 mb-4">
                    <label
                        class="form-label"
                        for="template_id-input"
                    >{{ $tc('tasks::templates.template', 2).ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <select
                        id="template_id-input"
                        v-model="selectedTemplate"
                        class="form-select"
                        name="template_id"
                    >
                        <option :value="null">
                            {{ $t('base.please_select').ucFirst() }}
                        </option>
                        <option v-for="(template, index) in templates" :key="index" :value="template">
                            {{ template.translation_key ? $t(template.translation_key).ucFirst() : template.name }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-12 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('tasks::tasks.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('tasks::tasks.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-3 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('tasks::tasks.columns.translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('tasks::tasks.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.type_id === false}"
                        class="form-label"
                        for="type_id-input"
                    >{{ $t('tasks::tasks.columns.type_id').ucFirst() }}</label
                    >
                    <select
                        id="type_id-input"
                        v-model="model.type_id"
                        :class="{'border-danger': states.type_id === false}"
                        class="form-select"
                        name="type_id"
                    >
                        <option :value="model.type_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.type_id').ucFirst() }}
                        </option>
                        <option v-for="(type, index) in types" :key="index" :value="type.id">
                            {{ type.translation_key ? $t(type.translation_key).ucFirst() : type.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.type_id === false}" class="invalid-feedback">{{ errors.type_id }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.priority_id === false}"
                        class="form-label"
                        for="priority_id-input"
                    >{{ $t('tasks::tasks.columns.priority_id').ucFirst() }}</label
                    >
                    <select
                        id="priority_id-input"
                        v-model="model.priority_id"
                        :class="{'border-danger': states.priority_id === false}"
                        class="form-select"
                        name="priority_id"
                    >
                        <option :value="model.priority_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.priority_id').ucFirst() }}
                        </option>
                        <option v-for="(priority, index) in priorities" :key="index" :value="priority.id">
                            {{ priority.translation_key ? $t(priority.translation_key).ucFirst() : priority.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.priority_id === false}" class="invalid-feedback">{{ errors.priority_id }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.status_id === false}"
                        class="form-label"
                        for="status_id-input"
                    >{{ $t('tasks::tasks.columns.status_id').ucFirst() }}</label
                    >
                    <select
                        id="status_id-input"
                        v-model="model.status_id"
                        :class="{'border-danger': states.status_id === false}"
                        class="form-select"
                        name="status_id"
                    >
                        <option :value="model.status_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.status_id').ucFirst() }}
                        </option>
                        <option v-for="(status, index) in statuses" :key="index" :value="status.id">
                            {{ status.translation_key ? $t(status.translation_key).ucFirst() : status.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.status_id === false}" class="invalid-feedback">{{ errors.status_id }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.start === false}"
                        class="form-label"
                        for="start"
                    >{{ $t('tasks::tasks.columns.start').ucFirst() }}</label
                    >
                    <input
                        id="start"
                        v-model="model.start"
                        :class="{'border-danger': states.start === false}"
                        :placeholder="$t('tasks::tasks.placeholders.start').ucFirst()"
                        class="form-control"
                        name="start"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.start === false}" class="invalid-feedback">{{ errors.start }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.end === false}"
                        class="form-label"
                        for="end"
                    >{{ $t('tasks::tasks.columns.end').ucFirst() }}</label
                    >
                    <input
                        id="end"
                        v-model="model.end"
                        :class="{'border-danger': states.end === false}"
                        :placeholder="$t('tasks::tasks.placeholders.end').ucFirst()"
                        class="form-control"
                        name="end"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.end === false}" class="invalid-feedback">{{ errors.end }}</div>
                </div>
                <div class="col-lg-3 mb-4" v-if="! model.is_generated">
                    <label
                        :class="{'text-danger': states.deadline === false}"
                        class="form-label"
                        for="deadline"
                    >{{ $t('tasks::tasks.columns.deadline').ucFirst() }}</label
                    >
                    <input
                        id="deadline"
                        v-model="model.deadline"
                        :class="{'border-danger': states.deadline === false}"
                        :placeholder="$t('tasks::tasks.placeholders.deadline').ucFirst()"
                        class="form-control"
                        name="deadline"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.deadline === false}" class="invalid-feedback">{{ errors.deadline }}</div>
                </div>
                <div class="col-lg-12 mb-4">

                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('tasks::tasks.columns.description').ucFirst() }}</label
                    >
                    <ckeditor
                        id="description"
                        :class="{'border-danger': states.description === false}"
                        v-model="model.description"
                        :editor="editor"
                        :config="editorConfig"
                        class="form-control rounded"
                        :placeholder="$t('tasks::tasks.placeholders.description').ucFirst()"
                    >
                    </ckeditor>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
                <div class="col-lg-12 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.description_translation_key === false}"
                        class="form-label"
                        for="description_translation_key"
                    >{{ $t('tasks::tasks.columns.description_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.description_translation_key"
                        :error="errors.description_translation_key"
                        :placeholder="$t('tasks::tasks.placeholders.description_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.description_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.description_translation_key === false}" class="invalid-feedback">
                        {{ errors.description_translation_key }}
                    </div>
                </div>
                <div class="col-lg-12 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.content === false}"
                        class="form-label"
                        for="content"
                    >{{ $t('tasks::tasks.columns.content').ucFirst() }}</label
                    >
                    <textarea
                        id="content"
                        v-model="model.content"
                        :class="{'border-danger': states.content === false}"
                        :placeholder="$t('tasks::tasks.placeholders.content').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.content === false}" class="invalid-feedback">
                        {{ errors.content }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4" v-if="false">
                    <label
                        :class="{'text-danger': states.content_translation_key === false}"
                        class="form-label"
                        for="content_translation_key"
                    >{{ $t('tasks::tasks.columns.content_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.content_translation_key"
                        :error="errors.content_translation_key"
                        :placeholder="$t('tasks::tasks.placeholders.content_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.content_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.content_translation_key === false}" class="invalid-feedback">
                        {{ errors.content_translation_key }}
                    </div>
                </div>
            </div>
            <template v-if="!own && !accessible">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.task_id === false}"
                        class="form-label"
                        for="task_id-input"
                    >{{ $t('tasks::tasks.columns.task_id').ucFirst() }}</label
                    >
                    <select
                        id="task_id-input"
                        v-model="model.task_id"
                        :class="{'border-danger': states.task_id === false}"
                        class="form-select"
                        name="task_id"
                    >
                        <option v-if="false" :value="model.task_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.task_id').ucFirst() }}
                        </option>
                        <option v-for="(task, index) in tasks" :key="index" :value="task.id">
                            {{ task.translation_key ? $t(task.translation_key).ucFirst() : task.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.task_id === false}" class="invalid-feedback">{{ errors.task_id }}</div>
                </div>
            </template>
            <div v-if="!own || !fixAssigned" class="row mb-4">
                <div class="col-lg-3 mb-4" v-if="!own && false">
                    <label
                        :class="{'text-danger': states.user_type === false}"
                        class="form-label"
                        for="user_type-input"
                    >{{ $t('tasks::tasks.columns.user_type').ucFirst() }}</label
                    >
                    <select
                        id="user_type-input"
                        v-model="model.user_type"
                        :class="{'border-danger': states.user_type === false}"
                        class="form-select"
                        name="user_type"
                    >
                        <option v-if="false" :value="model.user_type === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.user_type').ucFirst() }}
                        </option>
                        <option v-for="(modelType, index) in userModelTypes" :key="index" :value="modelType.value">
                            {{ modelType.label }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_type === false}" class="invalid-feedback">{{ errors.user_type }}</div>
                </div>

                <div class="col-lg-3 mb-4" v-if="!own">
                    <label
                        :class="{'text-danger': states.user_id === false}"
                        class="form-label"
                        for="user_id-input"
                    >{{ $t('tasks::tasks.columns.user_id').ucFirst() }}</label
                    >
                    <select
                        id="user_id-input"
                        v-model="model.user_id"
                        :class="{'border-danger': states.user_id === false}"
                        class="form-select"
                        name="user_id"
                    >
                        <option :value="model.user_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.user_id').ucFirst() }}
                        </option>
                        <option v-for="(user, index) in users" :key="index" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">{{ errors.user_id }}</div>
                </div>
                <div v-if="!fixAssigned && false" class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.assigned_type === false}"
                        class="form-label"
                        for="assigned_type-input"
                    >{{ $t('tasks::tasks.columns.assigned_type').ucFirst() }}</label
                    >
                    <select
                        id="assigned_type-input"
                        v-model="model.assigned_type"
                        :class="{'border-danger': states.assigned_type === false}"
                        class="form-select"
                        name="assigned_type"
                    >
                        <option v-if="false" :value="model.assigned_type === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.assigned_type').ucFirst() }}
                        </option>
                        <option v-for="(modelType, index) in userModelTypes" :key="index" :value="modelType.value">
                            {{ modelType.label }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.assigned_type === false}" class="invalid-feedback">{{ errors.assigned_type }}</div>
                </div>

                <div v-if="!fixAssigned && !own" class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.assigned_id === false}"
                        class="form-label"
                        for="assigned_id-input"
                    >{{ $t('tasks::tasks.columns.assigned_id').ucFirst() }}</label
                    >
                    <select
                        id="assigned_id-input"
                        v-model="model.assigned_id"
                        :class="{'border-danger': states.assigned_id === false}"
                        class="form-select"
                        name="assigned_id"
                    >
                        <option :value="model.assigned_id === undefined ? undefined : null">
                            {{ $t('tasks::tasks.placeholders.assigned_id').ucFirst() }}
                        </option>
                        <option v-for="(user, index) in assigned" :key="index" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.assigned_id === false}" class="invalid-feedback">{{ errors.assigned_id }}</div>
                </div>
                <template v-if="!fixClient">
                    <div v-if="!fixClient && false" class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.client_type === false}"
                            class="form-label"
                            for="client_type-input"
                        >{{ $t('tasks::tasks.columns.client_type').ucFirst() }}</label
                        >
                        <select
                            id="client_type-input"
                            v-model="model.client_type"
                            :class="{'border-danger': states.client_type === false}"
                            class="form-select"
                            name="client_type"
                        >
                            <option :value="model.client_type === undefined ? undefined : null">
                                {{ $t('tasks::tasks.placeholders.client_type').ucFirst() }}
                            </option>
                            <option v-for="(modelType, index) in clientModelTypes" :key="index" :value="modelType.value">
                                {{ modelType.label }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.client_type === false}" class="invalid-feedback">{{ errors.client_type }}</div>
                    </div>

                    <div v-if="!fixClient" class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.client_id === false}"
                            class="form-label"
                            for="client_id-input"
                        >{{ $t('tasks::tasks.columns.client_id').ucFirst() }}</label
                        >
                        <select
                            id="client_id-input"
                            v-model="model.client_id"
                            :class="{'border-danger': states.client_id === false}"
                            class="form-select"
                            name="client_id"
                        >
                            <option :value="model.client_id === undefined ? undefined : null">
                                {{ $t('tasks::tasks.placeholders.client_id').ucFirst() }}
                            </option>
                            <option v-for="(user, index) in clients" :key="index" :value="user.id">
                                {{ user.name }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.client_id === false}" class="invalid-feedback">{{ errors.client_id }}</div>
                    </div>
                </template>
            </div>

            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.users === false}"
                        class="form-label"
                        for="users"
                    >{{ $t('tasks::tasks.relationships.followers').ucFirst() }}</label>
                    <multiple-select id="users" v-model="model.users" store="user" morph="App\Models\User"></multiple-select>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.contributors === false}"
                        class="form-label"
                        for="contributors"
                    >{{ $t('tasks::tasks.relationships.contributors').ucFirst() }}</label>
                    <multiple-select id="contributors" v-model="model.contributors" store="user" morph="App\Models\User"></multiple-select>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.responsibles === false}"
                        class="form-label"
                        for="responsibles"
                    >{{ $t('tasks::tasks.relationships.responsibles').ucFirst() }}</label>
                    <multiple-select id="responsibles" v-model="model.responsibles" store="user" morph="App\Models\User"></multiple-select>
                </div>
            </div>

            <div v-if="!fixInducers" class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.inducers === false}"
                        class="form-label"
                        for="inducers"
                    >{{ $t('tasks::tasks.relationships.inducers').ucFirst() }}</label>
                    <multiple-select id="inducers" v-model="model.inducers" store="project" morph="Appon\Projects\Models\Project"></multiple-select>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.upload === false}"
                        class="form-label"
                        for="files"
                    >{{ $t('tasks::tasks.relationships.files').ucFirst() }}</label>
                    <div class="border rounded px-3 py-2 bg-white">
                        <multiple-file id="files" v-model="model.upload" :files="model.files" @update:files="(files) => model.files = files"></multiple-file>
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-lg-2">
                    <label
                        class="form-label"
                        for="is-active-input"
                    >{{ $t('base.create_new_item', {item: $tc('reminders::reminders.reminder', 1)}).ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is-active-input"
                            v-model="createReminder"
                            class="form-check-input"
                            name="repeating"
                            type="checkbox"
                        />
                    </div>
                </div>
                <div v-if="createReminder" class="col-lg-10">
                    <label
                        class="form-label"
                        for="is-active-input"
                    >{{ $tc('reminders::reminders.reminder', 1).ucFirst() }}</label
                    >
                    <reminder-form :reminder="reminder" :redirect="false" :doNotSave="true" :without-save-btn="true" @updated="(newReminder) => model.reminder = newReminder"></reminder-form>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
