<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "project-step-interaction-form",

    emits: ['saved'],

    props: {
        projectStepInteraction: {
            type: Object,
            default: () => { return{}; }
        },

        projectStep: {
            type: Object
        },

        interaction: {
            type: Object
        },

        onlySuccessful: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'projectStepInteraction/loading',
            'states': 'projectStepInteraction/states',
            'errors': 'projectStepInteraction/errors',
            projectSteps: 'projectStep/all',
            interactions: 'interaction/all',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        events: function () {
            return [
                {
                    value: 'Appon\\Projects\\Models\\Offer',
                    label: this.$tc('projects::offers.offer', 2).ucFirst()
                },
                {
                    value: 'Appon\\Projects\\Models\\Contract',
                    label: this.$tc('projects::contracts.contract', 2).ucFirst()
                },
                {
                    value: 'Appon\\Interactions\\Models\\Meeting',
                    label: this.$tc('interactions::meetings.meeting', 2).ucFirst()
                },
            ]
        },

        selectedEvents: function () {
            const store = this.getStoreByEvent()

            return store ? this.$store.getters[`${store}/all`] : [];
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {
        getStoreByEvent: function () {
            switch (this.model.event_type) {
                case 'Appon\\Projects\\Models\\Offer':
                    return 'offer'
                case 'Appon\\Projects\\Models\\Contract':
                    return 'contract'
                case 'Appon\\Interactions\\Models\\Meeting':
                    return 'meeting'
                default:
                    return ''
            }
        },

        fetchEvents: function () {
            const store = this.getStoreByEvent()

            if (store) {
                this.$store.dispatch(`${store}/all`)
            }
        },

        save: function () {
            // console.log(this.model);
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`projectStepInteraction/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved', saved)
                if (method === 'create' && this.redirect) {
                    this.$router.push(this.$t('routes.'+'/project-step-interactions/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            const base = {project_step_id: this.projectStep.id, interaction: this.interaction.id, interaction_id: this.interaction.id}

            if (this.onlySuccessful) {
                base.successful = true
            }

            if (!model?.realized_at) {
                base.realized_at = (new Date()).formattedDate()
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },
    },

    watch: {
        projectStepInteraction: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.projectStepInteraction)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-6" v-if="!projectStep">
                    <label
                        :class="{'text-danger': states.project_step_id === false}"
                        class="form-label"
                        for="project_step_id"
                    >{{ $t('projects::project_step_interactions.columns.project_step_id').ucFirst() }}</label>
                    <select
                        id="project_step_id"
                        v-model="model.project_step_id"
                        :class="{'border-danger': states.project_step_id === false}"
                        class="form-select"
                        name="project_step_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_step_interactions.placeholders.project_step_id').ucFirst() }}
                        </option>
                        <option v-for="(projectStep, key) in projectSteps" :key="key" :value="projectStep.id">{{
                                projectStep.step ? (projectStep.step.translation_key ? $t(projectStep.step.translation_key).ucFirst() : projectStep.step.name) : projectStep.id
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.project_step_id === false}" class="invalid-feedback">
                        {{ errors.project_step_id }}
                    </div>
                </div>
                <div class="col-lg-6" v-if="!interaction">
                    <label
                        :class="{'text-danger': states.interaction_id === false}"
                        class="form-label"
                        for="interaction_id"
                    >{{ $t('projects::project_step_interactions.columns.interaction_id').ucFirst() }}</label>
                    <select
                        id="interaction_id"
                        v-model="model.interaction_id"
                        :class="{'border-danger': states.interaction_id === false}"
                        class="form-select"
                        name="interaction_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_step_interactions.placeholders.interaction_id').ucFirst() }}
                        </option>
                        <option v-for="(interaction, key) in interactions" :key="key" :value="interaction.id">{{
                                interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.interaction_id === false}" class="invalid-feedback">
                        {{ errors.interaction_id }}
                    </div>
                </div>
            </div>
            <div class="row mb-4" v-if="!interaction || interaction?.event_type">
                <div class="col-lg-4" v-if="interaction?.event_type">
                    <label
                        :class="{'text-danger': states.event_type === false}"
                        class="form-label"
                        for="event_type"
                    >{{ $t('projects::project_step_interactions.columns.event_type').ucFirst() }}</label>
                    <select
                        id="event_type"
                        v-model="model.event_type"
                        :class="{'border-danger': states.event_type === false}"
                        class="form-select"
                        name="event_type"
                        @change="fetchEvents"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_step_interactions.placeholders.event_type').ucFirst() }}
                        </option>
                        <option v-for="(event, key) in events" :key="key" :value="event.value">{{
                                event.label
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.event_type === false}" class="invalid-feedback">
                        {{ errors.event_type }}
                    </div>
                </div>
                <div class="col-lg-4" v-if="!interaction || interaction.event_type">
                    <label
                        :class="{'text-danger': states.event_id === false}"
                        class="form-label"
                        for="event_id"
                    >{{ $t('projects::project_step_interactions.columns.event_id').ucFirst() }}</label>
                    <select
                        id="event_id"
                        v-model="model.event_id"
                        :class="{'border-danger': states.event_id === false}"
                        class="form-select"
                        name="event_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::project_step_interactions.placeholders.event_id').ucFirst() }}
                        </option>
                        <option v-for="(event, key) in selectedEvents" :key="key" :value="event.id">{{
                                event.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.event_id === false}" class="invalid-feedback">
                        {{ errors.event_id }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.notification === false}"
                        class="form-label"
                        for="notification"
                    >{{ $t('projects::project_step_interactions.columns.notification').ucFirst() }} *</label
                    >

                    <textarea
                        id="notification"
                        v-model="model.notification"
                        :class="{'border-danger': states.notification === false}"
                        :placeholder="$t('projects::project_step_interactions.placeholders.notification').ucFirst()"
                        class="form-control"
                    />
                    <div :class="{'d-block': states.notification === false}" class="invalid-feedback">{{ errors.notification }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.realized_at === false}"
                        class="form-label"
                        for="realized_at"
                    >{{ $t('projects::project_step_interactions.columns.realized_at').ucFirst() }}</label
                    >
                    <input
                        id="realized_at"
                        v-model="model.realized_at"
                        :class="{'border-danger': states.realized_at === false}"
                        :placeholder="$t('projects::project_step_interactions.placeholders.realized_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.realized_at === false}" class="invalid-feedback">
                        {{ errors.realized_at }}
                    </div>
                </div>
                <div class="col-lg-2" v-if="!onlySuccessful && model.failed != true">
                    <div class="bg-soft-success rounded px-2 py-1">
                        <label
                            class="form-label"
                            for="successful"
                        >{{ $t('projects::project_step_interactions.columns.successful').ucFirst() }}</label
                        >
                        <div class="form-check form-switch form-switch-md mb-3 ps-0">
                            <input id="successful" v-model="model.successful"
                                   class="form-check-input float-none mx-3" type="checkbox">
                        </div>
                        <div :class="{'d-block': states.successful === false}" class="invalid-feedback">
                            {{ errors.successful }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-2" v-if="!onlySuccessful && model.successful != true">
                    <div class="bg-soft-danger rounded px-2 py-1">
                        <label
                            class="form-label"
                            for="failed"
                        >{{ $t('projects::project_step_interactions.columns.failed').ucFirst() }}</label
                        >
                        <div class="form-check form-switch form-switch-md mb-3 ps-0">
                            <input id="failed" v-model="model.failed"
                                   class="form-check-input float-none mx-3" type="checkbox">
                        </div>
                        <div :class="{'d-block': states.failed === false}" class="invalid-feedback">
                            {{ errors.failed }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
