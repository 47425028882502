<script>
import {mapGetters} from "vuex";

export default {
    name: "balance-sheet",

    props: {
        projectId: {
            type: [String, Number],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            loading: 'project/balanceLoading',
            balance: 'project/balance'
        })
    },

    methods: {
        fetch: function () {
            this.$store.dispatch('project/balance', this.projectId);
        },
    },

    /*watch: {
        projectId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetch();
                }
            },
        }
    }*/
}
</script>

<template>
    <div class="card overflow-hidden">
        <b-overlay :show="loading">
            <div class="card-body p-0 overflow-hidden">
                <table class="table table-borderless m-0">
                    <tbody>
                    <tr class="text-primary">
                        <td><i class="fas fa-file-contract"></i></td>
                        <td>{{ $tc('projects::contracts.contract', 2).ucFirst() }}</td>
                        <td class="font-weight-bold">{{ balance?.contracts?.printCurrency('HUF') }}</td>
                    </tr>
                    <tr class="text-success">
                        <td><i class="fas fa-file-invoice-dollar"></i></td>
                        <td>{{ $tc('invoices::invoices.invoice', 2).ucFirst() }}</td>
                        <td class="font-weight-bold">{{ balance?.invoices?.printCurrency('HUF') }}</td>
                    </tr>
                    <tr class="bg-soft-warning text-danger">
                        <td><i class="fas fa-cash-register"></i></td>
                        <td>{{ $t('projects::projects.account_receivable').ucFirst() }}</td>
                        <td class="font-weight-bold">{{ balance?.difference?.printCurrency('HUF') }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-overlay>
    </div>
</template>

<style scoped>

</style>