<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import ProjectStepInteractionForm from "../forms/project-step-interaction-form.vue";
import MeetingForm from "../forms/meeting-form.vue";
import OfferForm from "../forms/offer-form.vue";
import ContractForm from "../forms/contract-form.vue";
import {render, h} from 'vue';
import RelatedUserManager from "./related-user-manager.vue";

export default {
    name: "project-step-tasks-card",

    components: {
        RelatedUserManager, ContractForm, OfferForm, MeetingForm, ProjectStepInteractionForm},

    computed: {
        ...mapGetters({
            projectStep: 'projectStep/item',
            loading: 'projectStep/tasksLoading',
            tasks: 'projectStep/tasks',
            projectStepInteraction: 'projectStepInteraction/item',
            project: 'project/item'
        })
    },

    emits: ['refresh', 'jump', 'edit'],

    data: function () {
        return {
            showModal: false,
            modalTitle: '',
            interaction: null,
            currentTask: null
        };
    },

    methods: {
        test: function () {
            Swal.fire({
                willOpen(popup) {
                    console.log(popup)
                    console.log(render(h('button', {onclick: () => { Swal.runCustom('swal2-custom'); }, innerHTML: 'NYOMJ', type: 'button', class: 'swal2-styled swal2-custom', 'aria-label': '' }, []), Swal.getActions()))
                }
            }).then(result => {
                console.log(result)
            });
        },

        openTask: function (task) {
            const date = new Date().formattedDate();

            if (!task.interaction && !task.event_type) {
                if (task.controller) {
                    if (task.controller.decision) {
                        const opportunities = task.controller.decision.opportunities;

                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.confirm_title'),
                            text: this.$t('base.confirm_message'),
                            showCancelButton: true,
                            showConfirmButton: true,
                            showDenyButton: true,
                            customClass: {
                                icon: 'text-warning border-warning',
                                confirmButton: 'btn btn-' + opportunities[0].color,
                                denyButton: 'btn btn-' +  + opportunities[1].color
                            },
                            confirmButtonText: opportunities[0].text.ucFirst(),
                            denyButtonText: opportunities[1].text.ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            const edited = {};
                            if (result.isConfirmed) {
                                task.controller.setter.forEach(setter => {
                                    edited[setter] = opportunities[0].value;
                                });
                                this.$emit('edit', edited);
                                this.$store.dispatch('task/close', {id: task.id, query: this.query}).then(() => {
                                    this.$store.dispatch('projectStep/removeTask', task.id);
                                });
                            }
                            if(result.isDenied) {
                                task.controller.setter.forEach(setter => {
                                    edited[setter] = opportunities[1].value;
                                });
                                this.$emit('edit', edited);
                                this.$store.dispatch('task/close', {id: task.id, query: this.query}).then(() => {
                                    this.$store.dispatch('projectStep/removeTask', task.id);
                                });
                            }
                        });
                    }
                } else {
                    this.closeTask(task.id);
                }
            }

            switch (task.interaction?.translation_key) {
                case 'interactions::interactions.interactions.phone':
                    this.currentTask = task;
                    this.interaction = task.interaction;
                    this.modalTitle = task.name;
                    this.showModal = true;
                    break;
                case 'interactions::interactions.interactions.meeting':
                    if (task.controller) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.what_happened').ucFirst(),
                            text: this.$t('interactions::meetings.how_the_trial_ended').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            showDenyButton: true,
                            customClass: {
                                icon: 'text-danger border-danger',
                                confirmButton: 'btn btn-success',
                                denyButton: 'btn btn-danger',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('base.successful').ucFirst(),
                            denyButtonText: this.$t('base.failed').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.$store.dispatch('meeting/realize', { id: task.event_id, data: { realized_at: (new Date()).formattedDate(), successful: true} }).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {
                                                id: task.id
                                            },
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                            if (result.isDenied) {
                                this.$store.dispatch('meeting/realize', { id: task.event_id, data: { realized_at: (new Date()).formattedDate(), successful: false} }).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {
                                                id: task.id
                                            },
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                        });
                    } else {
                        this.currentTask = task;
                        this.interaction = task.interaction;
                        this.modalTitle = task.name;
                        this.showModal = true;
                    }
                    break;
                case 'interactions::interactions.interactions.offer':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.what_happened').ucFirst(),
                            text: this.$t('projects::offers.customer_response').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            showDenyButton: true,
                            customClass: {
                                icon: 'text-danger border-danger',
                                confirmButton: 'btn btn-success',
                                denyButton: 'btn btn-danger',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('base.accepted').ucFirst(),
                            denyButtonText: this.$t('base.refused').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.$store.dispatch('offer/accept', task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                            if (result.isDenied) {
                                this.$store.dispatch('offer/reject', task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                        });
                    } else {
                        this.currentTask = task;
                        this.interaction = task.interaction;
                        this.modalTitle = task.name;
                        this.showModal = true;
                    }
                    break;
                case 'interactions::interactions.interactions.contract':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.what_happened').ucFirst(),
                            text: this.$t('projects::offers.customer_response').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            customClass: {
                                icon: 'text-danger border-danger',
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('projects::contracts.contract_is_signed').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                return this.$store.dispatch(`contract/signed`, task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('contract/setItem', {});
                                        this.$emit('refresh')
                                    });
                                })
                            }
                        });
                    } else {
                        this.currentTask = task;
                        this.interaction = task.interaction;
                        this.modalTitle = task.name;
                        this.showModal = true;
                    }
                    break;
                case 'interactions::interactions.interactions.implement':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'success',
                            title: this.$t('projects::implements.the_service_is_completed').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            customClass: {
                                icon: 'text-success border-success',
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('projects::implements.service_completed').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                return this.$store.dispatch(`implementPart/complete`, task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            { id: task.id },
                                            // task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('contract/setItem', {});
                                        this.$emit('refresh')
                                    });
                                })
                            }
                        });
                    }
                    break;
                case 'interactions::interactions.interactions.document':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'success',
                            title: this.$t('projects::documents.is_the_documentation_ready').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            customClass: {
                                icon: 'text-success border-success',
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('projects::documents.the_documentation_ready').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                return this.$store.dispatch(`document/realize`, task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            { id: task.id },
                                            // task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('contract/setItem', {});
                                        this.$emit('refresh')
                                    });
                                })
                            }
                        });
                    }
                    break;
                default:
                    if (task.event_type) {
                        this.$emit('jump', task.event_type, 1);
                    }
            }

        },

        closeTask: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-warning border-warning',
                    confirmButton: 'btn btn-warning',
                    cancelButton: 'btn btn-warning'
                },
                confirmButtonText: this.$t('base.close_it').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('task/close', {id: id, query: this.query}).then(() => {
                        this.$store.dispatch('projectStep/removeTask', id);
                    });
                }
            });
        },

        saved: function (event) {
            this.showModal = false;
            const date = new Date().formattedDate();
            switch (this.currentTask.interaction?.translation_key) {
                case 'interactions::interactions.interactions.phone':
                    this.$store.dispatch('task/update',
                        Object.assign(
                            {},
                            this.currentTask,
                            { closed_at: date, event_type: 'Appon\\Projects\\Models\\ProjectStepInteraction', event_id: event.id }
                        )
                    ).then(() => {
                        this.$store.dispatch('projectStep/removeTask', this.currentTask.id);
                        this.$emit('refresh');
                    });
                    break;
                case 'interactions::interactions.interactions.meeting':
                    this.$store.dispatch(`projectStepInteraction/create`, {
                        project_step_id: this.projectStep?.id,
                        interaction_id: this.interaction?.id,
                        meeting: Object.assign({}, event, {
                            partner_type: this.project?.target_type,
                            partner_id: this.project?.target_id
                        })
                    }).then(projectStepInteraction => {
                        this.$store.dispatch('task/update',
                            Object.assign(
                                {},
                                this.currentTask,
                                { closed_at: date, event_type: projectStepInteraction.event_type, event_id: projectStepInteraction.event_id }
                            )
                        ).then(() => {
                            this.$store.dispatch('projectStep/removeTask', this.currentTask.id);
                            this.$store.dispatch('projectStepInteraction/setItem', {})
                            this.$store.dispatch('meeting/setItem', {});
                            this.$emit('refresh')
                        });
                    });
                    break;
                case 'interactions::interactions.interactions.offer':
                    this.$store.dispatch(`projectStepInteraction/create`, {
                        project_step_id: this.projectStep?.id,
                        interaction_id: this.interaction?.id,
                        offer: Object.assign({}, event, {
                            project_id: this.project?.id
                        })
                    }).then(projectStepInteraction => {
                        this.$store.dispatch('task/update',
                            Object.assign(
                                {},
                                this.currentTask,
                                {
                                    closed_at: date,
                                    event_type: projectStepInteraction.event_type,
                                    event_id: projectStepInteraction.event_id
                                }
                            )
                        ).then(() => {
                            this.$store.dispatch('projectStep/removeTask', this.currentTask.id);
                            this.$store.dispatch('projectStepInteraction/setItem', {})
                            this.$store.dispatch('offer/setItem', {});
                            this.$emit('refresh')
                        });
                    });
                    break;
                case 'interactions::interactions.interactions.contract':
                    this.$store.dispatch(`projectStepInteraction/create`, {
                        project_step_id: this.projectStep?.id,
                        interaction_id: this.interaction?.id,
                        contract: Object.assign({}, event, {
                            project_id: this.project?.id
                        })
                    }).then(projectStepInteraction => {
                        this.$store.dispatch('task/update',
                            Object.assign(
                                {},
                                this.currentTask,
                                {
                                    closed_at: date,
                                    event_type: projectStepInteraction.event_type,
                                    event_id: projectStepInteraction.event_id
                                }
                            )
                        ).then(() => {
                            this.$store.dispatch('projectStep/removeTask', this.currentTask.id);
                            this.$store.dispatch('projectStepInteraction/setItem', {})
                            this.$store.dispatch('offer/setItem', {});
                            this.$emit('refresh')
                        });
                    });
                    break;
            }
        },

        clear: function () {
            this.showModal = false;
            this.modalTitle = '';
            this.interaction = null;
            this.currentTask = null;
        },

        changeTaskAssigned: function (task, user) {
            this.$store.dispatch('projectStep/setTaskLoading', {id: task.id, loading: true});
            this.$store.dispatch('task/setItem', task);
            this.$store.dispatch('task/update', {id: task.id, assigned_type: 'App\\Models\\User', assigned_id: user?.id}).then(() => {
                this.$store.dispatch('projectStep/changeTaskAssigned', {id: task.id, user: user});
                this.$store.dispatch('task/setItem', {});
                this.$store.dispatch('projectStep/setTaskLoading', {id: task.id, loading: false});
            }).catch(() => {
                this.$store.dispatch('projectStep/setTaskLoading', {id: task.id, loading: false});
            });
        },
    },

    watch: {
        projectStep: {
            immediate: true,
            deep: true,
            handler: function (projectStep) {
                if (projectStep?.id) {
                    this.$store.dispatch('projectStep/tasks', projectStep.id);
                }
            },
        }
    },

    beforeMount() {
        if (typeof Swal.runCustom === 'undefined') {
            Swal.runCustom = function () {
                const buttons = Swal.getActions().getElementsByTagName('button');
                for (let button of buttons) {
                    button.style.display = 'none';
                }
                Swal.showLoading();
                // Swal.close();
                console.log('RUN CUSTOM');
            };
        }
    },

    beforeUnmount() {
        if (Swal.runCustom) {
            delete Swal.runCustom;
        }
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div v-if="false" class="btn btn-dark" @click="test" data-swal-trigger="swal-test">TEST</div>
        <div class="notebook" :key="tasks?.length">
            <ul class="list">
                <li>{{ $tc('tasks::tasks.task', 2).ucFirst() }}</li>
                <li v-for="(task, index) in tasks" :key="index">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex h-100">
                            <span class="max-width-50px text-center">
                                <i class="fas fa-check text-success font-size-16 cursor-pointer" @click="openTask(task)"></i>
                            </span>
                            <span>
                                <router-link :to="$t('routes./tasks/accessible-tasks/:id(\\d+)', {id: task.id}).rmValidation()">
                                    {{ task.translation_key ? $t(task.translation_key).ucFirst() : task.name }}
                                </router-link>
                            </span>
                        </div>
                        <div class="d-flex align-items-center">
                            <div v-if="!$store.getters['projectStep/taskLoading'](task.id)" class="d-inline-block">
                                <related-user-manager
                                    v-model="task.assigned"
                                    store="user"
                                    :list="false"
                                    :return-user="true"
                                    @update:modelValue="(user) => changeTaskAssigned(task, user)"
                                ></related-user-manager>
                            </div>
                            <div v-else>
                                <b-spinner tag="strong" variant="primary" type="grow"></b-spinner>
                            </div>
                            <div class="d-inline-flex mx-2" v-if="task.deadline" :title="task.deadline.printDate()">
                                <i class="fas fa-calendar-alt font-size-15" :class="{'opacity-50 text-muted': !task.expired, 'text-danger': task.expired}"></i>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex justify-content-between h-100">
                        <div class="d-flex h-100">
                            <span class="max-width-50px text-center">
                            </span>
                            <span></span>
                        </div>
                        <div class="d-flex align-items-center">
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <b-modal size="xl" body-bg-variant="light" body-class="rounded" v-model="showModal" :title="modalTitle" @close="clear" hide-footer>
            <template v-if="interaction?.translation_key === 'interactions::interactions.interactions.phone'">
                <project-step-interaction-form v-if="showModal" :key="showModal"
                                               :only-successful="true"
                                               :redirect="false"
                                               :project-step-interaction="projectStepInteraction"
                                               :project-step="projectStep"
                                               :interaction="interaction"
                                               @saved="saved"
                >
                </project-step-interaction-form>
            </template>
            <template v-else-if="interaction?.translation_key === 'interactions::interactions.interactions.meeting'">
                <meeting-form
                    :meeting="meeting"
                    :redirect="!fix"
                    :fixed="true"
                    :own="true"
                    :doNotSave="true"
                    @updated="(newMeeting) => saved(newMeeting)"
                ></meeting-form>
            </template>
            <template v-else-if="interaction?.translation_key === 'interactions::interactions.interactions.offer'">
                <offer-form
                    :offer="offer"
                    :redirect="!fix"
                    :fixed="true"
                    :doNotSave="true"
                    @updated="(newOffer) => saved(newOffer)">
                </offer-form>
            </template>
            <template v-else-if="interaction?.translation_key === 'interactions::interactions.interactions.contract'">
                <contract-form
                    :contract="contract"
                    :redirect="!fix"
                    :fixed="true"
                    :doNotSave="true"
                    @updated="(newContract) => saved(newContract)">

                </contract-form>
            </template>
        </b-modal>
    </b-overlay>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

    .notebook {
        // background-color: #f5f5f5;
        width: 100%;
        margin: 0 auto;
        padding: 0;

        h4 {
            color: #cd0000;
            font-size: 24px;
            letter-spacing: -2px;
            text-align: left;
        }
        .list {
            color: #555;
            font-size: 14px;
            padding: 0 !important;
            width: 100%;
            /*font-family: courier, monospace;*/
            border: 1px solid $card-border-color;
            border-radius: $card-border-radius;
            box-shadow: $box-shadow;
            background-color: $white;
        }
        .list li {
            list-style: none;
            border-bottom: 1px dotted #ccc;
            height: auto;
            text-transform: capitalize;

            &:first-of-type {
                color: #cd0000;
                font-weight: 500;
                font-size: 24px;
                text-align: left;
                border-bottom-style: double;
                padding: 10px 20px;
            }

            &:nth-last-child(2) {
                border-bottom-style: double;
            }

            &:last-of-type {
                height: 30px;
                border-bottom: 0;
            }

            span {
                display: inline-block;
                padding: 6px 10px;
            }

            span:first-child {
                display: inline-block;
                width: 50px;
                border-right: 1px solid #ffaa9f;
                margin-right: 2px;

                i {
                    display: none;
                }

                &:hover i {
                    display: inline-block;
                }
            }

            span:nth-child(2) {
                border-left: 1px solid #ffaa9f;
            }

            &:hover:not(:last-of-type):not(:first-of-type) {
                background-color: #f0f0f0;
                transition: all 0.2s;
            }
        }

        .lines {
            border-left: 1px solid #ffaa9f;
            border-right: 1px solid #ffaa9f;
            width: 2px;
            float: left;
            height: 100%;
            margin-left: 40px;
        }
    }


</style>