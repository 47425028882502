<template>
    <div class="row justify-content-end justify-content-xl-start">
        <template v-if="project.id">
            <div :class="{'col-lg-8': !closed, 'col flex-grow-1': closed}">
                <div class="card" v-if="project.id">
                    <div class="card-body">
                        <project-steps-manager v-if="project?.process_id" :project="project"></project-steps-manager>
                        <span v-else>{{ $t('projects::projects.no_process_selected').ucFirst() }}</span>
                    </div>
                    <hr>
                    <b-overlay :show="!projectStep?.id">
                        <div class="card-body">
                            <project-step-form :fix="true" :project-step="projectStep" :required-status="requiredStatus" :required-interactions="requiredInteractions" @modified="refreshProgressLine">
                                <template #buttons v-if="!project?.closed_at">
                                    <b-button variant="outline-warning" class="ms-2" @click="step">
                                        <span>{{ $t('base.next').ucFirst() }}</span>
                                        <span class="ms-2">
                                        <i class="fas fa-arrow-right"></i>
                                    </span>
                                    </b-button>
                                </template>
                            </project-step-form>
                        </div>
                    </b-overlay>
                    <b-button v-if="project.closed_at" variant="white" class="position-absolute text-secondary lock cursor-pointer" @click="unlock">
                        <i class="fas fa-lock font-size-26"></i>
                    </b-button>
                </div>
            </div>
            <div :class="{'col-lg-4': !closed, 'col flex-grow-0': closed}">
                <project-step-deadline-card v-if="projectStep?.id" :project-step-id="projectStep?.id"></project-step-deadline-card>

                <tab-vertical @closed="(value) => closed = value" @index="resetManualIndex" :default-index="project.closed_at ? 0 : 1" :manual-index="manualIndex">
                    <b-tab :title="project.target?.name" :marked="requiredPartner">
                        <template #icon>
                            <i class="fas fa-handshake"></i>
                        </template>
                        <partner-card
                            :id="project.target_id"
                            v-if="project.target_type === 'Appon\\Partners\\Models\\Partner'"
                            :modal="modal"
                            @modified="refreshProgressLine"
                            @close="jumpBack"
                        ></partner-card>
                    </b-tab>
                    <b-tab :title="$tc('tasks::tasks.task', 2).ucFirst()">
                        <template #icon>
                            <i class="fas fa-tasks"></i>
                        </template>
                        <project-step-tasks-card @refresh="refreshProgressLine" @jump="jumpManually" @edit="edit"></project-step-tasks-card>
                    </b-tab>
                    <b-tab v-if="!project.closed_at" :title="$t('projects::project_steps.requirements').ucFirst()">
                        <template #icon>
                            <i class="fas fa-info-circle"></i>
                        </template>
                        <div class="card">
                            <div class="card-header" :class="{'bg-warning': requirements?.length || requirementsLoading, 'bg-soft-success rounded-bottom': !requirements?.length && !requirementsLoading}">
                                <div class="card-title text-center">
                                    <h5 class="mb-0">
                                        <i class="fas me-2 position-relative"
                                            :class="{'fa-exclamation-triangle text-danger': requirements?.length || requirementsLoading, 'fa-check-circle text-success': !requirements?.length && !requirementsLoading}"
                                        >
                                            <span class="icon-bg-white"></span>
                                        </i>
                                        <span>{{ $t('projects::project_steps.' + (!requirements?.length && !requirementsLoading ? 'requirements_are_completed' : 'requirements_to_complete')).ucFirst() }}</span>
                                    </h5>
                                </div>
                            </div>
                            <div v-if="requirements?.length || requirementsLoading" class="card-body bg-soft-warning rounded-bottom">
                                <b-overlay :show="requirementsLoading">
                                    <div class="card-text">
                                        <ul class="list-unstyled requirements">
                                            <li v-for="(requirement, index) in requirements" :key="index" v-html="requirement" @click.capture.prevent.stop="jump($event)"></li>
                                        </ul>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('projects::project_steps.history').ucFirst()">
                        <template #icon>
                            <i class="fas fa-history"></i>
                        </template>
                        <project-history v-if="project?.id" :project-id="project.id"></project-history>
                    </b-tab>
                    <b-tab :title="$t('projects::project_steps.history').ucFirst()">
                        <template #icon>
                            <i class="fas fa-money-bill"></i>
                        </template>
                        <balance-sheet class="mb-4" v-if="project?.id" :project-id="project.id"></balance-sheet>
                    </b-tab>
                </tab-vertical>

            </div>
        </template>
        <div class="col-lg-12 mt-4 ">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title d-flex justify-content-between align-items-center">
                        <span>{{ title }}</span>
                        <span>
                            <template v-if="project?.canceled_at">
                                <b-button :title="$t('base.reopen').ucFirst()" variant="white" class="text-warning lock-icon cursor-pointer ms-2" @click="reopen">
                                    <i class="fas fa-trash-restore-alt font-size-16"></i>
                                </b-button>
                                <span v-if="project?.canceled_at" class="text-muted font-size-12 ms-2 d-none d-sm-inline-block">{{ project.canceled_at.printDate() }}</span>
                            </template>
                            <template v-else-if="project?.closed_at">
                                <b-button :title="$t('base.unlock').ucFirst()" variant="white" class="lock-icon cursor-pointer ms-2" :class="{'text-success': project.successful, 'text-danger': project.failed}" @click="unlock">
                                    <i class="fas fa-lock font-size-16"></i>
                                </b-button>
                                <span v-if="project?.closed_at" class="text-muted font-size-12 ms-2 d-none d-sm-inline-block">{{ project.closed_at.printDate() }}</span>
                            </template>
                        </span>
                    </h4>
                </div>
                <div class="card-body">
                    <project-form id="project-form" name="project-form" :project="project" :own="own" :required-fields="requiredFields">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </project-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import ProjectForm from "../../../components/forms/project-form.vue";
import ProjectStepsManager from "../../../components/elements/project-steps-manager.vue";
import ProjectStepForm from "../../../components/forms/project-step-form.vue";
import PartnerCard from "../../../components/elements/partner-card.vue";
import Swal from "sweetalert2";
import TabVertical from "../../../components/elements/tab-vertical.vue";
import ProjectStepTasksCard from "../../../components/elements/project-step-tasks-card.vue";
import ProjectStepDeadlineCard from "../../../components/elements/project-step-deadline-card.vue";
import ProjectHistory from "../../../components/elements/project-history.vue";
import BalanceSheet from "../../../components/elements/balance-sheet.vue";

export default {
    props: {
        own: {
            type: Boolean,
            default: false
        }
    },

    components: {
        BalanceSheet,
        ProjectHistory,
        ProjectStepDeadlineCard,
        ProjectStepTasksCard,
        TabVertical, PartnerCard, ProjectStepForm, ProjectStepsManager, ProjectForm, CallbackButton},

    computed: {
        ...mapGetters({
            partner: 'partner/item',
            project: 'project/item',
            stepErrors: 'project/stepErrors',
            projectStep: 'projectStep/item',
            requirements: 'projectStep/requirements',
            requiredInteractions: 'projectStep/interactions',
            requiredStatus: 'projectStep/status',
            requiredRelations: 'projectStep/relations',
            requirementsLoading: 'projectStep/requirementsLoading',
            admin: 'auth/admin'
        }),

        title: function () {
            return this.project.id ? this.$tc('projects::projects.project', 2).ucFirst() + ' / ' + (this.project.translation_key ? this.$t(this.project.translation_key).ucFirst() : this.project.name) : this.$t('base.create_new_item', {item: this.$tc('projects::projects.project', 1)}).ucFirst()
        },

        link: function () {
            const translationKey = 'routes.' + (this.own ? '/projects/my-projects' : '/projects/accessible-projects')
            return {path: this.$t(translationKey)}
        },

        requiredFields: function () {
            return this.$store.getters['projectStep/requiredFields']('project') ?? [];
        },

        requiredPartner: function () {
            return Object.keys(this.requiredRelations).some(relation => {
                return ['partner', 'decisionMaker', 'contact'].includes(relation);
            });
        },
    },

    data: function () {
        return {
            open: false,
            closed: false,
            manualIndex: null,
            modal: '',
            backIndex: null
        };
    },

    methods: {
        resetManualIndex: function (index) {
            if (this.manualIndex !== null && this.manualIndex !== index) {
                this.manualIndex = null;
                this.modal = '';
            }
        },

        jumpBack: function () {
            if (this.backIndex !== null) {
                this.manualIndex = this.backIndex;
                this.backIndex = null;
                this.modal = '';
            }
        },

        jump: function (event) {
            if (event.target.nodeName === 'A') {
                const model = event.target.attributes.href?.value;

                this.jumpManually(model);
            }
        },

        jumpManually: function (model, backIndex = null) {
            if (backIndex !== null) {
                this.backIndex = backIndex;
            }

            switch (model) {
                case 'partner':
                    this.manualIndex = 0;
                    this.modal = 'partner';
                    break;
                case 'decisionMakersReciprocal':
                case 'decisionMaker':
                    this.manualIndex = 0;
                    this.modal = 'decision';
                    break;
                case 'contactsReciprocal':
                case 'contact':
                    this.manualIndex = 0;
                    this.modal = 'contact';
                    break;
                case 'project':
                    document.getElementById('project-form').scrollIntoView();
                    break;
            }
        },

        back: function () {
            this.$router.push(this.link)
        },

        step: function () {
            this.$store.dispatch('project/step', {id: this.$route.params.id, query: { with: ['target', 'projectType', 'process', 'step', 'user', 'status', 'contributors', 'users', 'followers'] }})
            this.$store.dispatch('step/steps', {project_id: this.project.id || this.$route.params.id});
        },

        refreshProgressLine: function () {
            this.$store.dispatch('project/status', {id: this.$route.params.id });
            this.$store.dispatch('step/steps', {project_id: this.project.id || this.$route.params.id});
            this.fetchProjectStepRequirements(this.projectStep);
            this.fetchProjectStepDeadlines(this.projectStep);
            this.fetchProjectBalance(this.project?.id);
            this.fetchHistory(this.project?.id);
        },

        fetchProjectStepRequirements: function (projectStep = null) {
            projectStep = projectStep?.id ? projectStep : this.projectStep;

            if (projectStep?.id) {
                this.$store.dispatch('projectStep/requirements', projectStep?.id).then(() => {

                });
            } else {
                this.$store.dispatch('projectStep/clearRequirements');
            }
        },

        fetchProjectBalance: function (id) {
            if (id) {
                this.$store.dispatch('project/balance', id);
            } else {
                this.$store.dispatch('project/clearBalance');
            }
        },

        fetchHistory(id) {
            if (id) {
                this.$store.dispatch('project/history', id);
            } else {
                this.$store.dispatch('project/clearHistory');
            }
        },

        fetchProjectStepDeadlines: function (projectStep = null) {
            projectStep = projectStep?.id ? projectStep : this.projectStep;

            if (projectStep?.id) {
                this.$store.dispatch('projectStep/deadlines', projectStep?.id).then(() => {

                });
            } else {
                this.$store.dispatch('projectStep/clearDeadlines');
            }
        },

        unlock: function () {
            if (!this.admin) {
                return
            }
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-warning border-warning',
                    confirmButton: 'btn btn-warning',
                    cancelButton: 'btn btn-warning'
                },
                confirmButtonText: this.$t('base.unlock').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('project/unlock', {id: this.project.id || this.$route.params.id, closed_at: ''});
                }
            });
        },

        reopen: function () {
            if (!this.admin) {
                return
            }
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-warning border-warning',
                    confirmButton: 'btn btn-warning',
                    cancelButton: 'btn btn-warning'
                },
                confirmButtonText: this.$t('base.reopen').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('project/update', {id: this.project.id || this.$route.params.id, canceled_at: '', successful: '', failed: '', closed_at: ''});
                }
            });
        },

        edit: function (edited = {}) {
            this.$store.dispatch('projectStep/update', Object.assign({}, this.projectStep, edited)).then(() => {
                this.refreshProgressLine();
            });
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },

        projectStep: {
            immediate: true,
            deep: true,
            handler: function (projectStep) {
                this.fetchProjectStepRequirements(projectStep);
                this.fetchProjectStepDeadlines(projectStep);
            },
        },

        project: {
            handler: function (project) {
                this.fetchProjectBalance(project?.id);
                this.fetchHistory(project?.id);
                if (this.$route.hash) {
                    const name = this.$route.hash.substring(1);

                    setTimeout(() => {
                        document.getElementById(name).scrollIntoView();
                    }, 400);
                }
            },
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('project/show', {id: this.$route.params.id, query: { with: ['target', 'projectType', 'process', 'step', 'user', 'status', 'contributors', 'users', 'followers'] }}).then(response => {
                const data = response?.data

                if (data?.target_type === 'Appon\\Partners\\Models\\Partner') {
                    this.$store.dispatch('partner/setItem', data?.target)
                }

                if (!data.process_id) {
                    this.$store.dispatch('projectStep/list', { project_id: data.id, step_id: data.step_id }).then(list => {
                        if (list?.length) {
                            this.$store.dispatch('projectStep/setItem', list[0]);
                        }
                    })
                }
            })
        }
    },

    unmounted() {
        this.$store.dispatch('step/clearStepsWithProjectSteps')
        this.$store.dispatch('projectStep/clearItem')
        this.$store.dispatch('project/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

    .rounded-bottom {
        border-bottom-left-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;
    }

    .fa-check-circle {
        background-color: white;
        clip-path: circle(46% at 50% 50%);
        font-size: 20px;
    }

    .lock {
        top: 10px;
        left: 10px;
        opacity: 0.5;
        transition: 0.3s;
    }

    .lock-icon {
        opacity: 0.5;
        transition: 0.3s;
    }

    .card:hover {
        .lock, .lock-icon {
            opacity: 1;
            transition: 0.3s;
        }
    }

    .requirements {
        li {
            :deep(ul) {
                list-style: disc !important;
                li {
                    font-weight: 700;
                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
</style>
