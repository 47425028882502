<script>
import {mapGetters} from "vuex";
import ObjectPropertyList from "./object-property-list.vue";
import PartnerForm from "../forms/partner-form.vue";
import ContactManager from "../../views/pages/contacts/manage.vue";
import DecisionMakerManager from "../../views/pages/decision-makers/manage.vue";
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: "partner-card",

    components: {TransitionToggle, PartnerForm, ObjectPropertyList, ContactManager, DecisionMakerManager},

    emits: ['modified', 'toggle', 'close'],

    props: {
        id: {
            type: Number,
            required: true
        },

        toggleable: {
            type: Boolean,
            default: false
        },

        modal: {
            type: String
        }
    },

    computed: {
        ...mapGetters({
            partner: 'partner/item',
            companySizes: 'companySize/all',
            leadSources: 'leadSource/all',
            industries: 'industry/all',
            contact: 'contact/item',
        }),

        requiredFields: function () {
            return this.$store.getters['projectStep/requiredFields']('partner') ?? [];
        },

        contactRequiredFields: function () {
            return this.$store.getters['projectStep/requiredFields']('contact') ?? [];
        },

        decisionMakerRequiredFields: function () {
            return this.$store.getters['projectStep/requiredFields']('decisionMaker') ?? [];
        },

        properties: function () {
             const arr = [
                    {
                        key: 'name',
                        translation: this.$t('partners::partners.columns.name').ucFirst(),
                        versioned: false
                    },
                    {
                        key: 'registration_number',
                        translation: this.$t('partners::partners.columns.registration_number').ucFirst(),
                        versioned: false
                    },
                    {
                        key: 'vat_number',
                        translation: this.$t('partners::partners.columns.vat_number').ucFirst(),
                        versioned: false
                    },
                    {
                        key: 'email',
                        translation: this.$t('partners::partners.columns.email').ucFirst(),
                        versioned: false
                    },
                    {
                        key: 'phone',
                        translation: this.$t('partners::partners.columns.phone').ucFirst(),
                        versioned: false
                    },
                    {
                        key: 'international_vat_number',
                        translation: this.$t('partners::partners.columns.international_vat_number').ucFirst(),
                        versioned: false
                    },
                    {
                        key: 'company_size',
                        translation: this.$t('partners::partners.relationships.company_size').ucFirst(),
                        versioned: false,
                        selected: true
                    },
                    {
                        key: 'industry',
                        translation: this.$t('partners::partners.relationships.industry').ucFirst(),
                        versioned: false,
                        selected: true
                    },
                    {
                        key: 'lead_source',
                        translation: this.$t('partners::partners.relationships.lead_source').ucFirst(),
                        versioned: false,
                        selected: true
                    }
                ]

                this.partner?.fields?.forEach(field => {
                    const item = {
                        key: field.transformed_name,
                        translation: this.$t(field.translation_key).ucFirst(),
                        versioned: field.versionable,
                        unit: field.unit,
                    }

                    if (field?.options?.options?.length) {
                        item.options = field.options?.options?.reduce((obj, option) => {
                            obj[option.value] = option.translation_key
                            return obj
                        }, {})
                    }

                    arr.push(item);
                })

            return arr
        },
    },

    data: function () {
        return {
            tab: 'partner',
            open: !this.toggle
        }
    },

    mounted() {
        this.$store.dispatch('partner/show', {id: this.id, query: {with: ['industry', 'leadSource', 'companySize']}})
        if (!this.companySizes || (this.companySizes && !this.companySizes.length)) {
            this.$store.dispatch('companySize/all')
        }
        if (!this.leadSources || (this.leadSources && !this.leadSources.length)) {
            this.$store.dispatch('leadSource/all')
        }
        if (!this.industries || (this.industries && !this.industries.length)) {
            this.$store.dispatch('industry/all')
        }
    },

    methods: {
        toggle: function () {
            if (!this.toggleable) {
                return;
            }
            this.$emit('toggle');
            this.open = !this.open;
        },

        close: function () {
            this.$emit('close');
        },
    },

    watch: {
        modal: {
            handler: function (tab) {
                this.tab = tab || 'partner';
            },
        }
    },

    unmounted() {
        this.$store.dispatch('partner/clearItem');
    }
}
</script>

<template>
    <div class="card" :class="{'vertical-modified': toggleable && !open}">
        <div class="card-header bg-soft-primary" :class="{'p-0 rounded-custom': toggleable && !open}">
            <h4 class="card-title text-center" :class="{'cursor-pointer': toggleable, 'mx-1 my-2': toggleable && !open}" @click="toggle">
                <span>{{ partner?.name }}</span>
            </h4>
        </div>
        <transition-toggle>
            <div class="card-body" v-if="!toggleable || (toggleable && open)">
                <b-overlay :show="!partner.id">
                    <object-property-list class="border-bottom py-2" :item="partner" :properties="properties" :open="!!this.modal" @close="close">
                        <template #modal="{ close }">
                            <div class="d-flex mb-4">
                                <div class="w-100 text-center cursor-pointer rounded-top py-2" :class="{'text-primary bg-soft-primary': tab === 'partner'}" @click="tab = 'partner'">
                                    <i class="fas fa-home me-2"></i>
                                    <span>{{ $tc('partners::partners.partner', 1).ucFirst() }}</span>
                                </div>
                                <div class="w-100 text-center cursor-pointer rounded-top py-2" :class="{'text-primary bg-soft-primary': tab === 'contact'}" @click="tab = 'contact'">
                                    <i class="fas fa-address-card me-2"></i>
                                    <span>{{ $tc('partners::contacts.contact', 2).ucFirst() }}</span>
                                </div>
                                <div class="w-100 text-center cursor-pointer rounded-top py-2" :class="{'text-primary bg-soft-primary': tab === 'decision'}" @click="tab = 'decision'">
                                    <i class="fas fa-user-tie me-2"></i>
                                    <span>{{ $tc('partners::decision_makers.decision_maker', 2).ucFirst() }}</span>
                                </div>
                            </div>
                            <partner-form v-if="tab === 'partner'" :partner="partner" :required-fields="requiredFields" @saved="$emit('modified')">
                                <template #buttons>
                                    <b-button class="ms-2" variant="secondary" @click="close">{{ $t('base.close').ucFirst() }}</b-button>
                                </template>
                            </partner-form>
                            <contact-manager v-if="tab === 'contact'" :required-fields="contactRequiredFields" :partner-id="partner?.id" @saved="$emit('modified')"></contact-manager>
                            <decision-maker-manager v-if="tab === 'decision'" :required-fields="decisionMakerRequiredFields" :partner-id="partner?.id" @saved="$emit('modified')"></decision-maker-manager>
                        </template>
                    </object-property-list>
                </b-overlay>
            </div>
        </transition-toggle>
    </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

    .vertical-modified {
        writing-mode: vertical-lr;
        text-orientation: upright;
    }

    .rounded-custom {
        border-top-right-radius: 0;
        border-bottom-left-radius: $card-border-radius;
    }
</style>
