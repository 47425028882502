<script>
import {defineComponent} from 'vue'
import HorizontalTimeline from "./horizontal-timeline.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default defineComponent({
    name: "project-steps-manager",

    components: {HorizontalTimeline},

    props: {
        project: {
            type: Object
        },
    },

    data: function () {
        return {
            openModal: false,
            editModal: false,
            newStep: {}
        }
    },

    computed: {
        ...mapGetters({
            loading: 'projectStep/loading',
            projectStep: 'projectStep/item',
            stepsWithProjectStepsLoading: 'step/stepsWithProjectStepsLoading',
            listedStep: 'step/list',
            step: 'step/item',
            steps: 'step/stepsWithProjectSteps'
        }),

        processId: function () {
            return this.project && this.project.process_id
        }
    },

    methods: {
        add: function () {
            this.newStep.process_id = this.processId
            this.newStep.sort = this.nextSort
            this.openModal = true
        },

        remove: function (id = null) {
            if (!this.steps || !this.steps.length) {
                return
            }

            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    const step = id ? {} : this.steps[this.steps.length - 1]

                    this.$store.dispatch('step/destroy', step.id || id)
                }
            });
        },

        edit: function (id) {
            this.$store.dispatch('step/select', id)
            this.editModal = true
        },

        select: function (id) {
            if (!id) {
                return
            }

            const step = this.steps.find(item => item.id === id);

             if (step?.project_step) {
                this.$store.dispatch('projectStep/setItem', step?.project_step);
                }

             // console.log(step, this.steps, id, this.listedStep.find(all => all.id === id))

            /*if (step && step.project_step) {
                this.$store.dispatch('projectStep/setItem', step.project_step);
            } else if (step) {
                this.$store.dispatch('projectStep/create', {project_id: this.project.id, step_id: step.id});
            }*/

            this.$store.dispatch('step/setItem', this.listedStep.find(all => all.id === id))
        },

    },

    watch: {
        steps: {
            deep: true,
            handler: function (steps) {
                if (!steps) {
                    return
                }

                const step = steps.find(item => item.id === this.step?.id);

                if (step?.project_step) {
                    this.$store.dispatch('projectStep/setItem', step?.project_step);
                }
            }
        },

        listedStep: {
            deep: true,
            handler: function (steps) {
                if (!steps || !this.project) {
                    return
                }

                this.select(Number(this.project?.step_id))
            }
        },

        step: function (value) {
            const step = this.steps.find(item => item.id === value?.id)

            if (step?.project_step) {
                this.$store.dispatch('projectStep/setItem', step?.project_step);
            }
        },

        project: {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (!value?.id) {
                    return
                }

                this.$store.dispatch('step/steps', {project_id: this.project.id}).then(() => {
                    if (!this.step.id) {
                        this.select(Number(this.project.step_id))
                    }
                });
            },
        }
    },

    created() {
        this.$store.dispatch('step/list', {process_id: this.processId, sorting: { column: 'sort', desc: false }, with: ['statuses', 'interactions']})
    }
})
</script>

<template>
    <b-overlay :show="stepsWithProjectStepsLoading">
        <div id="project-step-manager" class="d-flex position-relative">
            <horizontal-timeline :steps="steps" :key="steps.length" :current-step-id="Number(project.step_id)" class="flex-grow-1 pt-4 overflow-x-scroll overflow-y-visible solid-scroller pb-4 pb-xxl-0" @edit="edit" :sortable="false" @remove="remove" @select="select"></horizontal-timeline>
        </div>
    </b-overlay>
</template>

<style scoped>
    .btn {
        width: 32px;
        height: 32px;
        opacity: 0.5;
        transition: 0.3s;
        &:hover {
            opacity: 1;
        }
    }
</style>
